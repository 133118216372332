<template>
  <div>
    <el-menu
      class="el-menu-demo"
      mode="horizontal"
      background-color="#F0F0F0"
      text-color="#0C0808"
      active-text-color="#E4610F"
      :ellipsis="false"
      :router="true"
    >
      <el-menu-item index="/">
        <el-image style="width: 150px; height: 60px" src="TAFE-logo-black.png" fit="scale-down" />
        <el-text tag="b" size="large" class="arc-primary-font mt-2"> <h4>&nbsp; RISK AND ADAPTATION PLATFORM</h4></el-text>
      </el-menu-item>
      <div class="flex-grow" />
      <el-sub-menu index="2">
        <template #title><i class="fa-solid fa-bars"></i></template>
        <el-menu-item index="/"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><House /></el-icon> Home</el-text></el-menu-item>
        <el-menu-item index="/about"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><Collection /></el-icon> About</el-text></el-menu-item>
        <el-menu-item index="/methodology"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><Cpu /></el-icon> Methodology</el-text></el-menu-item>
        <el-menu-item index="/portfolio" @click="setActiveAssessment('portfolio')"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><Suitcase /></el-icon> Portfolio Assessment</el-text></el-menu-item>
        <el-menu-item index="/region" @click="setActiveAssessment('region')"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><LocationInformation /></el-icon> Regional Assessments</el-text></el-menu-item>
        <el-menu-item index="/campus" @click="setActiveAssessment('campus')"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><School /></el-icon> Campus assessments</el-text></el-menu-item>
        <el-menu-item index="/support"><el-text tag="b" class="arc-primary-font"> <el-icon class="arc-orange-font"><Service /></el-icon> Support</el-text></el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>
  
<script>
import {
  House,
  Collection,
  Cpu,
  Suitcase,
  LocationInformation,
  School,
  Service
} from '@element-plus/icons-vue'
export default {
  name: 'AppHeader',
  components: {
    House,
    Collection,
    Cpu,
    Suitcase,
    LocationInformation,
    School,
    Service
  },
  methods: {
    setActiveAssessment: function(item) {
      this.$store.commit('mainModule/setCurrentAssessment', item)
      // this.$router.push({ path: '/' + item })
    }
  }
}
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
.el-sub-menu__title {
    color: #E4610F !important
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
</style>


  