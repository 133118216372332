<template>
  <el-scrollbar max-height="95dvh">
    <div class="common-layout">
      <el-container>
        <el-header>
          <el-row class="mt-2">
            <el-col>
              <el-card shadow="always"> 
                <el-row>
                  <el-col :span="1" class="custom-icon-space">
                    <el-avatar :size="30" class="arc-light-orange-bg"> <el-icon><School /></el-icon> </el-avatar>
                  </el-col>
                  <el-col :span="20">
                    <el-breadcrumb separator="/" class="mt-2">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Campus Assessment</el-breadcrumb-item>
                      <!-- <el-breadcrumb-item>{{ campusLabel }}</el-breadcrumb-item> -->
                    </el-breadcrumb>
                  </el-col>
                  <el-col :span="3">
                    <!-- {{ "currentCampusCode: " + currentCampusCode}} -->
                    <!-- <el-select v-model="selectedCampus" placeholder="Select Campus" size="large">
                      <el-option
                        v-for="item in campusList"
                        :key="item.code"
                        :label="item.label"
                        :value="item.code"
                      />
                    </el-select> -->
                  </el-col>
                </el-row>
                
              </el-card>
            </el-col>
          </el-row>
          
        </el-header>
        <el-main class="mt-3">
          <el-tabs type="border-card" tab-position="bottom" >
            <el-tab-pane>
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-earth-americas"></i>
                  <el-text tag="b" class="arc-primary-font"> Climate Hazard Explorer</el-text>
                </span>
              </template>

              <!-- <template v-if="sceneId">
                <FlexMap mode="3d" :mapSceneId="sceneId" id="assetContextMap"/>
              </template>
              <template v-else>
                <FlexMap mode="2d" :mapSceneId="mapId" id="assetContextMap"/>
              </template> -->
              <iframe src="https://aus.gis.arcadis.com/portal/apps/dashboards/ab3f18b773a546d4ac29bacaacc1f5e3"
              class="custom-iframe-gis"
              ></iframe>

            </el-tab-pane>
            <el-tab-pane>
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-square-poll-vertical"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk Model Dashboard</el-text>
                </span>
              </template>
              <el-card shadow="never" class="mb-3">
                <el-text class="mx-1 arc-primary-font" tag="b">Select Campus <el-icon><School /></el-icon></el-text>
                <el-select
                  v-model="selectedCampus"
                  placeholder="Select Campus"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in campusList"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                  />
                </el-select>
              </el-card>
              <el-card >
                <iframe :src="dashboardURL"
                class="custom-iframe-powerbi"
                ></iframe>
              </el-card>
            </el-tab-pane>
            <el-tab-pane>
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-table"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk and Adaptation Register</el-text>
                </span>
              </template>
              <el-card shadow="never" class="mb-3">
                <el-text class="mx-1 arc-primary-font" tag="b">Select Campus <el-icon><School /></el-icon></el-text>
                <el-select
                  v-model="selectedCampus"
                  placeholder="Select Campus"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in campusList"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                  />
                </el-select>
              </el-card>
              <el-card shadow="never" class="mb-3">
                <CampusDashboard
                  :initialCRAList="initialCRAList"
                />
              </el-card>
              <el-card shadow="never" class="mb-3">
                <CampusAssessmentFilter
                  @changeFilter="handleOnChangeFilter"
                  :initialAdvanceFilters="advanceFilters"
                />
              </el-card>
              <el-tabs
                v-model="activeRegisterTab"
                tab-position="left"
              >
                <el-tab-pane label="RISK" name="riskTab">
                  <RiskTable
                    @fetchCRA="handleOnFetchCRA"
                    :initialCRAList="initialCRAList"
                    :initialLoadstate="initialLoadstate"
                    type="campus"
                  />
                </el-tab-pane>
                <el-tab-pane label="ADAPTATION" name="adaptationTab">
                  <AdaptationTable
                    @fetchCRA="handleOnFetchCRA"
                    :initialCRAList="initialCRAList"
                    :initialCampusLabel="initialCampusLabel"
                    :initialLoadstate="initialLoadstate"
                    type="campus"
                  />
                </el-tab-pane>
              </el-tabs>
              
            </el-tab-pane>
          </el-tabs>
        </el-main>
        <el-footer></el-footer>
      </el-container>
    </div>
  </el-scrollbar>
</template>

<script>
/*eslint-disable */
import {
  Suitcase,
  LocationInformation,
  School
} from '@element-plus/icons-vue'
import FlexMap from './FlexMap.vue'
import RiskTable from './RiskTable.vue'
import AdaptationTable from './AdaptationTable.vue'
import CampusAssessmentFilter from './CampusAssessmentFilter.vue'
import CampusDashboard from './CampusDashboard.vue'
import { ref } from 'vue'
import { baseApiUrl } from "../../config.js";
import axios from 'axios';
import { ElNotification } from 'element-plus'

export default {
  name: 'Campus',
  components: {
    Suitcase,
    LocationInformation,
    School,
    FlexMap,
    RiskTable,
    AdaptationTable,
    CampusAssessmentFilter,
    CampusDashboard,
  },
  data() {
    return {
      activeRegisterTab: ref('riskTab'),
      tabledata: [],
      selectedCampus: null,
      advanceFilters: {
        selectedAssetLevel2: "all",
        selectedClimateHazard: "all",
        selectedConsequence: "all",
        selectedRiskType: "all",
        selectedSTConsequence: "all",
        selectedSTLikelihood: "all",
        selectedSTRating: "all",
        selectedLTConsequence: "all",
        selectedLTLikelihood: "all",
        selectedLTRating: "all",
      },
      rawCRAList: [],
      initialCampusLabel: null,
      initialLoadstate: false,
    }
  },
  computed: {
    campusList: {
        get() {
            return this.$store.state.mainModule.campusList
        }
    },
    currentCampusCode: {
        get() {
            return this.$store.state.mainModule.currentCampus?this.$store.state.mainModule.currentCampus:"albury"
        }
    },
    campusLabel: {
        get() {
            // return this.$store.state.mainModule.campusList
            return this.$store.state.mainModule.campusList.filter(obj=>{return obj.code == this.$store.state.mainModule.currentCampus})[0].label
        }
    },
    sceneId: {
        get() {
            return this.$store.state.mainModule.assessmentMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentAssessment)[0].sceneId
        }
    },
    mapId: {
        get() {
            return this.$store.state.mainModule.assessmentMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentAssessment)[0].mapId
        }
    },
    currentAssessment: {
        get() {
            return this.$store.state.mainModule.currentAssessment
        }
    },
    dashboardURL: {
        get() {
            const climateDashboardURL = this.$store.state.mainModule.assessmentMapScenes.filter(
                obj => obj.code == this.$store.state.mainModule.currentAssessment
            )[0]?.climateDashboardURL
            const campusLabel = this.$store.state.mainModule.campusList.filter(
                obj => { return obj.code == this.$store.state.mainModule.currentCampus}
            )[0]?.label
            return `${climateDashboardURL}&$filter=Risk_x0020_Model/Location eq '${campusLabel}'`
        }
    },
    initialCRAList: {
      get() {
        let filteredList = this.rawCRAList;

        if (this.advanceFilters.selectedClimateHazard !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedClimateHazard === cra.climateHazardCode
          )
        }
        if (this.advanceFilters.selectedAssetLevel2 !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedAssetLevel2 === cra.subAssetTypeCode
          )
        }
        if (this.advanceFilters.selectedConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedConsequence === cra.consequenceCategoryCode
          )
        }
        if (this.advanceFilters.selectedRiskType !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedRiskType === cra.riskTypeCode
          )
        }
        // Short term
        if(this.advanceFilters.selectedSTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTConsequence === cra.riskAssessmentConsequenceSTCode
          )
        }
        if(this.advanceFilters.selectedSTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTLikelihood === cra.riskAssessmentLikelihoodSTCode
          )
        }
        if(this.advanceFilters.selectedSTRating !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTRating === cra.riskAssessmentRatingSTCode
          )
        }
        // Long term
        if(this.advanceFilters.selectedLTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTConsequence === cra.riskAssessmentConsequenceLTCode
          )
        }
        if(this.advanceFilters.selectedLTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTLikelihood === cra.riskAssessmentLikelihoodLTCode
          )
        }
        if(this.advanceFilters.selectedLTRating !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTRating === cra.riskAssessmentRatingLTCode
          )
        }
        
        return filteredList
      }
    }
  },
  watch: {
    selectedCampus(val) {
      this.$store.commit('mainModule/setCurrentCampus', val)
      this.handleOnFetchCRA(val)
    },
  },
  methods: {
    handleOnFetchCRA: function(campusCode) {
      const current = this
      current.initialLoadstate = true

      const url = baseApiUrl + 'campusClimateRiskEntries'
      const data = {
        params: { campusCode },
      }

      if (campusCode) {
        axios.get(url, data).then(response=>{
          if (response.data) {
            current.rawCRAList = response.data.entries
            current.initialCampusLabel = response.data.campus.label
          }
        }).catch(() => {
          ElNotification({
            type: 'error',
            message: 'Failed to get data' + campusCode + "...",
            title: 'ERROR'
          })
        }).finally(() => {
          current.initialLoadstate = false
        })
      } else {
        current.initialLoadstate = false
      }
    },
    handleOnChangeFilter: function({ field, value }) {
      if (field === "selectedCampus") {
        this.selectedCampus = value
      } else {
        this.advanceFilters[field] = value
      }
    }
  },
  beforeMount(){
    const current = this
    const currentAssessmentItem = current.$route.path.replace("/", "")
    this.$store.commit('mainModule/setCurrentAssessment', currentAssessmentItem)
    current.selectedCampus = current.currentCampusCode
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}
.custom-iframe-powerbi {
  min-height: 55vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
.custom-iframe-gis {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
</style>
  