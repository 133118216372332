<template>
  <el-scrollbar max-height="95dvh">
    <el-container>
      <el-header>
        <el-row class="mt-2">
          <el-col>
            <el-card shadow="never"> 
              <el-row>
                <el-col :span="1" class="custom-icon-space">
                  <el-avatar :size="30" class="arc-light-orange-bg"> <el-icon><Cpu /></el-icon> </el-avatar>
                </el-col>
                <el-col :span="23">
                  <el-breadcrumb separator="/" class="mt-2">
                    <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                    <el-breadcrumb-item>Methodology</el-breadcrumb-item>
                  </el-breadcrumb>
                </el-col>
              </el-row>
              
            </el-card>
          </el-col>
        </el-row>
        
      </el-header>
      <el-main class="mt-2">
        <el-card>
          <el-tabs
            v-model="activeMethodologyTab"
            tab-position="left"
          >
            <el-tab-pane label="RAAP configuration approach" name="configApproachTab">
              <el-text tag="b" size="large" class="arc-orange-font"><h4>RAAP configuration approach</h4></el-text>
              <el-text tag="p"  class="arc-primary-font mt-3">
                The RAAP is a climate change risk assessment tool that is configured to an organization through a range of data inputs, including organizational enterprise risk management frameworks, TAFE NSW asset management data, publicly  provided by TAFE NSW, publicly available climate change data. These inputs are processed in a risk model, which then configures site and portfolio-level risk registers. These preliminary risk assessments are tailored to the unique context of each location and provide a robust starting point for detailed risk assessment at the site level, as well as the consolidation of these findings to inform an overall portfolio level risk profile.  
              </el-text>
              <el-text tag="p"  class="arc-primary-font mt-3">
                A visual representation of the assessment process is summarized below:
              </el-text>
              <el-image style="width: 100%" src="MethodologyDiagram.png" fit="fill" />
              <el-text tag="p"  class="arc-primary-font mt-3">
                For more information about the configuration of the RAAP, please refer to the <b>Configuration Guide</b>.
              </el-text>
            </el-tab-pane>
            <el-tab-pane label="Configuration guide" name="configGuideTab">
              <el-card shadow="always">
                <iframe src="TAFE NSW RAAP Configuration Guide v1.0.pdf"
                  class="custom-iframe-pdf"
                  ></iframe>
              </el-card>
              
            </el-tab-pane>
          </el-tabs>

        </el-card>
      </el-main>
      <el-footer class="">
      </el-footer>
    </el-container>
  </el-scrollbar>
</template>

<script>
/*eslint-disable */
import {
  Cpu
} from '@element-plus/icons-vue'
import { ref } from 'vue'
export default {
  name: 'Methodology',
  components: {
    Cpu
  },
  data() {
    return {
      activeMethodologyTab: ref('configApproachTab'),
    }
  },
  
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}
.el-tabs--border-card>.el-tabs__header {
    background-color: #F0F0F0 !important
}
.custom-iframe-pdf {
  min-height: 73vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
</style>
  