<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-row class="mt-2">
          <el-col>
            <el-card shadow="never"> 
              <el-row>
                <el-col :span="1" class="custom-icon-space">
                  <el-avatar :size="30" class="arc-light-orange-bg"> <el-icon><Collection /></el-icon> </el-avatar>
                </el-col>
                <el-col :span="23">
                  <el-breadcrumb separator="/" class="mt-2">
                    <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                    <el-breadcrumb-item>About</el-breadcrumb-item>
                  </el-breadcrumb>
                </el-col>
              </el-row>
              
            </el-card>
          </el-col>
        </el-row>
        
      </el-header>
      <el-scrollbar max-height="95dvh">
        <el-main class="mt-2">
          <el-card shadow="always">
            <el-collapse v-model="activeName">
              <el-collapse-item name="1">
                <template #title>
                  <el-text tag="b" size="large" class="arc-orange-font"> What is the Risk Assessment and Adaptation Platform?</el-text>
                </template>
                <div>
                  <el-text tag="p" size="small" class="arc-primary-font">
                    The Risk Assessment and Adaptation Platform (RAAP), developed by Arcadis, is a tool designed to facilitate rapid, scalable climate change risk assessments across large portfolios of built environment assets.<br> By adopting a risk-based approach and providing a unified platform for identifying and mitigating priority risk issues, users of the RAAP can:
                  </el-text>
                  <br>
                  <b-list-group>
                    <b-list-group-item>
                      <el-text tag="p" class="arc-primary-font" size="small">Identify, understand and assess priority climate change risks at the asset and portfolio level;</el-text>
                    </b-list-group-item>
                    <b-list-group-item>
                      <el-text tag="p" class="arc-primary-font" size="small">Develop adaptation strategies; and </el-text>
                    </b-list-group-item>
                    <b-list-group-item>
                      <el-text tag="p" class="arc-primary-font" size="small">Consolidate climate risk assessment findings and insights for streamlined reporting.</el-text>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </el-collapse-item>
              <el-collapse-item name="2">
                <template #title>
                  <el-text tag="b" size="large" class="arc-orange-font"> What are the key features of the RAAP?</el-text>
                </template>
                <div>
                  <el-text tag="p" class="arc-primary-font" size="small">
                    The project will have the following objectives:
                  </el-text>
                  <br>
                  <el-row :gutter="20" >
                    <el-col :span="16">
                      <b-list-group>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Communication of climate risk: The RAAP provides an easy-to-navigate platform to support engagement into climate risks and potential adaptation actions at the portfolio, site and asset level.</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Identification of climate risk: The RAAP supports rapid identification and assessment of climate change risks at the asset level and is fully customizable to the organizational context.</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Prioritisation of responses to climate change risk: In alignment with key standards and guidelines, the RAAP supports focus on areas that require further investigation or investment, enabling effective resource allocation.</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Seizing opportunities: The RAAP enhances understanding and capacity to respond to climate-related opportunities, enabling proactive action.</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Improving enterprise and campus-level oversight and management of climate risks: The RAAP provides better visibility and management of climate risks at both the organisational and campus levels to increase resilience.</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Embedding climate risk management: The RAAP integrates climate risk management into existing frameworks and procedures, facilitating the ability to embed climate risk consideration in operational and strategic processes.</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Adaptation and building climate resilience: The RAAP informs and enables decisions regarding risk treatments to be implemented over time, supporting the development of climate resilience strategies. </el-text>
                        </b-list-group-item>
                      </b-list-group>
                    
                    </el-col>
                    <el-col :span="8" >
                      <el-image style="width: 100%" src="AboutPageDiagram.png" fit="fill" />
                      <el-text tag="i" class="arc-primary-font" size="small">
                        The RAAP captures enterprise risk, asset management and best available climate change data to determine climate change risk profiles at the site level. The RAAP supports continual improvement and capacity building by providing a single source of truth for climate risk management that is tailored to the asset management approach and increases in value through broad uptake.
  
                      </el-text>
                    </el-col>
                  </el-row>
                
                </div>
              </el-collapse-item>
            </el-collapse>   
          </el-card>
        </el-main>

      </el-scrollbar>
      <el-footer class="">
        
      </el-footer>
    </el-container>
  </div>
</template>

<script>
/*eslint-disable */
import {
  Collection
} from '@element-plus/icons-vue'
import { ref } from 'vue'
export default {
  name: 'About',
  components: {
    Collection
  },
  data() {
    return {
      activeName: ref(['1','2'])
    }
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}
.el-tabs--border-card>.el-tabs__header {
    background-color: #F0F0F0 !important
}
.custom-iframe-pdf {
  min-height: 77vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
</style>
  