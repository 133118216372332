<template>
  <el-collapse>
    <el-collapse-item>
      <template #title>
        <el-text class="mx-1 arc-orange-font" tag="b" size="large"><el-icon><DataAnalysis /></el-icon> DASHBOARD</el-text>
      </template>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8"></el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Changes in Risk Ratings Through Time"
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <SankeyChart
                chartId="riskPrePostAdaptChart"
                :isDataLoading="loadState"
                :isDataLoaded="dataLoaded"
                :chartData="riskRatingChangesData"
                :chartOptions="riskRatingChangesOptions"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8"></el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Number of risks their ratings before and after adaptation."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <BarChart
                chartId="riskPrePostAdaptChart"
                :isDataLoading="loadState"
                :isDataLoaded="dataLoaded"
                :chartData="riskPrePostAdaptData"
                :chartOptions="riskPrePostAdaptChartOptions"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8">
                <el-select
                  v-model="selectedSubAssetChartTerm"
                  placeholder="Select Term"
                  size="small"
                  filterable
                >
                  <el-option
                    v-for="item in termSelection"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Summary of risk ratings associated with different asset components."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart sub-asset-chart-container">
              <BarChart
                chartId="subAssetChart"
                :isDataLoading="loadState"
                :chartData="subAssetData"
                :chartOptions="subAssetOptions"
                cssClasses="sub-asset-chart"
              />
              <!-- <DoughnutChart
                :chartData="subAssetData"
                :chartOptions="subAssetOptions"
              /> -->
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8"></el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Graph of the different Adaptation categories."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <PieChart
                chartId="adaptationActionChart"
                :chartData="adaptationActionData"
                :chartOptions="adaptationActionOptions"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8">
                <el-select
                  v-model="selectedRiskChartTerm"
                  placeholder="Select Term"
                  size="small"
                  filterable
                >
                  <el-option
                    v-for="item in termSelection"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Proportion of campus-level climate risk ratings across each future timescale."
                >
                  <template #reference>
                    <el-button circle >
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <PieChart
                chartId="riskProportionChart"
                :chartData="rikProportionData"
                :chartOptions="riskProportionOptions"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8">
                <el-select
                  v-model="selectedHazardChartTerm"
                  placeholder="Select Term"
                  size="small"
                  filterable
                >
                  <el-option
                    v-for="item in termSelection"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Summary of risk ratings associated with each key climate change hazard."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <BarChart
                chartId="hazardChart"
                :isDataLoading="loadState"
                :chartData="hazardData"
                :chartOptions="hazardOptions"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mt-2">
        <el-col :span="8">
          <el-card class="adaptation-table-card">
            <el-row justify="space-between">
              <el-col :span="8"></el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Percentage of Adaptations complete across time periods."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class="adaptation-table-title my-3"><el-icon><TrendCharts /></el-icon>Adaptation Actions</div>
            <el-table :data="adaptationActionTable" :cell-class-name="cellClass">
              <el-table-column prop="timing" label="Timing" width="190">
                <template #default="scope">
                  <div class="timing-column">{{ scope.row.timing }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="count" label="Count" />
              <el-table-column prop="implemented" label="Implemented" width="115"/>
              <el-table-column prop="percent" label="%" />
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8">
                <el-select
                  v-model="selectedHazardCombinedChartTerm"
                  placeholder="Select Term"
                  size="small"
                  filterable
                >
                  <el-option
                    v-for="item in termSelection"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Summary of risk ratings associated with each key climate change hazard."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <DoughnutChart
                chartId="hazardCombinedChart"
                :chartData="hazardCombinedData"
                :chartOptions="hazardCombinedOptions"
              />
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card>
            <el-row justify="space-between">
              <el-col :span="8"></el-col>
              <el-col :span="2">
                <el-popover
                  placement="bottom"
                  title=""
                  :width="300"
                  trigger="click"
                  content="Summary of risk ratings associated with each key climate change hazard."
                >
                  <template #reference>
                    <el-button circle>
                      <el-icon><QuestionFilled /></el-icon>
                    </el-button>
                  </template>
                </el-popover>
              </el-col>
            </el-row>
            <div class= "small-chart">
              <PieChart
                chartId="hazardPieChart"
                :chartData="hazardPieData"
                :chartOptions="hazardPieOptions"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
/*eslint-disable */
import { QuestionFilled, DataAnalysis, TrendCharts } from '@element-plus/icons-vue'
import PieChart from './charts/PieChart.vue';
import BarChart from './charts/BarChart.vue';
import DoughnutChart from './charts/DoughnutChart.vue';
import SankeyChart from './charts/SankeyChart.vue';

export default {
  name: "CampusDashboard",
  components: {
    PieChart,
    QuestionFilled,
    BarChart,
    DoughnutChart,
    DataAnalysis,
    SankeyChart,
    TrendCharts,
  },
  props: ["initialCRAList"],
  data() {
    return {
      loadState: false,
      dataLoaded: true,
      riskPrePostAdaptChartOptions: {
        scales: {
          x: {
            grid: {
              display: false
            },
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
        },
        plugins: {
          title: {
            text: 'Risk rating pre/post adaptation',
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            position: 'left',
            labels: {
              boxWidth: 10,
              font: {
                size: 9
              }
            }
          }
        },
        maintainAspectRatio: false,
      },
      riskProportionOptions: {
        plugins: {
          title: {
            text: "Risk proportion by time and rating",
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          maintainAspectRatio: true,
          responsive: true,
        }
      },
      termSelection: [
        { label: "Short Term", value: "st" },
        { label: "Long Term", value: "lt" },
      ],
      selectedRiskChartTerm: "st",
      hazardOptions: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
        },
        plugins: {
          title: {
            text: "Risk ratings by climate hazard",
            display: true,
            font: {
              family: "Segoe UI",
              size: 15,
              weight: "bold",
              lineHeight: 1.2,
            },
          },
          legend: {
            position: "left",
            labels: {
              boxWidth: 10,
              font: {
                size: 9
              }
            }
          }
        },
        maintainAspectRatio: false,
      },
      selectedHazardPieChartTerm: "st",
      hazardPieOptions: {
        plugins: {
          title: {
            text: "Sources (climate hazards) of moderate, high and very high risks",
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          maintainAspectRatio: true,
          responsive: true,
        }
      },
      selectedHazardChartTerm: "st",
      subAssetOptions: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
        },
        plugins: {
          title: {
            text: "Risk ratings by sub asset component",
            display: true,
            font: {
              family: "Segoe UI",
              size: 15,
              weight: "bold",
              lineHeight: 1.2,
            },
          },
          legend: {
            position: "left",
            labels: {
              boxWidth: 10,
              font: {
                size: 9
              }
            }
          }
        },
        maintainAspectRatio: false,
        /* config for doughnut chart
        plugins: {
          title: {
            text: "Sub asset components with moderate, high and very high risks",
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          tooltips: {
            enabled: true,
            callbacks: {
              title: function(tooltipItem, data) {
                return data.datasets[tooltipItem[0]['datasetIndex']].label;
              }
            }
          },
          maintainAspectRatio: true,
          responsive: true,
        }
        */
      },
      selectedSubAssetChartTerm: "st",
      hazardCombinedOptions: {
        plugins: {
          title: {
            text: "Sources (climate hazards) of moderate, high and very high risks",
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function(tooltipItem) {
                const value = tooltipItem.raw
                const total = tooltipItem?.dataset.data.reduce(function(previousValue, currentValue) {
                  return previousValue + currentValue;
                });
                if (!total) {
                  return "0%"
                }
                const percentage = value / total * 100
                return `${tooltipItem.label}: ${Math.round((percentage + Number.EPSILON) * 100) / 100}%`;
              }
            }
          },
          maintainAspectRatio: true,
          responsive: true,
        }
      },
      selectedHazardCombinedChartTerm: "st",
      riskRatingChangesOptions: {
        plugins: {
          title: {
            text: "Changes in Risk Ratings Through Time",
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function(tooltipItem) {
                // format "from" label
                const tempFrom = tooltipItem.raw.from.split("_")
                tempFrom.pop()
                const from = tempFrom.map(string => string[0].toUpperCase() + string.slice(1)).join(" ")

                // format "to" label
                const tempTo = tooltipItem.raw.to.split("_")
                tempTo.pop()
                const to = tempTo.map(string => string[0].toUpperCase() + string.slice(1)).join(" ")

                return `${from} -> ${to}: ${tooltipItem.raw.flow}`
              }
            }
          },
          maintainAspectRatio: false,
          responsive: true,
        }
      },
      adaptationActionOptions: {
        plugins: {
          title: {
            text: "Percentage of Adaptations incomplete",
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            display: true,
            position: "left",
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          maintainAspectRatio: true,
          responsive: true,
        }
      }
    }
  },
  computed: {
    riskProportionDataSet() {
      let field = "riskAssessmentRatingLTCode"
      if (this.selectedRiskChartTerm === "st") {
        field = "riskAssessmentRatingSTCode"
      }

      let vlCount = 0
      let lCount = 0
      let mCount = 0
      let hCount = 0
      let vhCount = 0

      this.initialCRAList.forEach(cra => {
        if (cra[field] === "very_low") {
          vlCount ++
        } else if (cra[field] === "low") {
          lCount ++
        } else if (cra[field] === "moderate") {
          mCount ++
        } else if (cra[field] === "high") {
          hCount ++
        } else if (cra[field] === "very_high") {
          vhCount ++
        }
      });

      return [vlCount, lCount, mCount, hCount, vhCount]
    },
    rikProportionData() {
      return {
        labels: ['Very Low', 'Low', 'Moderate', 'High', 'Very High'],
        datasets: [
          {
            backgroundColor: ['#46d267', '#28a745', '#ffc107', '#FF9900', '#dd4b39'],
            data: this.riskProportionDataSet,
          }
        ]
      }
    },
    riskPrePostAdaptDataSet() {
      let riskLT = "riskAssessmentRatingLTCode"
      let riskST = "riskAssessmentRatingSTCode"
      let adaptLT = "adaptationRiskRatingLTCode"
      let adaptST = "adaptationRiskRatingSTCode"

      let riskLTCount = {
        veryLow: 0,
        low: 0,
        moderate: 0,
        high: 0,
        veryHigh: 0
      }
      let adaptLTCount = {
        veryLow: 0,
        low: 0,
        moderate: 0,
        high: 0,
        veryHigh: 0
      }
      let riskSTCount = {
        veryLow: 0,
        low: 0,
        moderate: 0,
        high: 0,
        veryHigh: 0
      }
      let adaptSTCount = {
        veryLow: 0,
        low: 0,
        moderate: 0,
        high: 0,
        veryHigh: 0
      }

      this.initialCRAList.forEach(cra => {
        if (cra[riskLT] === "very_low") {
          riskLTCount.veryLow ++
        } else if (cra[riskLT] === "low") {
          riskLTCount.low ++
        } else if (cra[riskLT] === "moderate") {
          riskLTCount.moderate ++
        } else if (cra[riskLT] === "high") {
          riskLTCount.high ++
        } else if (cra[riskLT] === "very_high") {
          riskLTCount.veryHigh ++
        }
      });

      this.initialCRAList.forEach(cra => {
        if (cra[riskST] === "very_low") {
          riskSTCount.veryLow ++
        } else if (cra[riskST] === "low") {
          riskSTCount.low ++
        } else if (cra[riskST] === "moderate") {
          riskSTCount.moderate ++
        } else if (cra[riskST] === "high") {
          riskSTCount.high ++
        } else if (cra[riskST] === "very_high") {
          riskLTCount.veryHigh ++
        }
      });

      this.initialCRAList.forEach(cra => {
        if (cra[adaptLT] === "very_low") {
          adaptLTCount.veryLow ++
        } else if (cra[adaptLT] === "low") {
          adaptLTCount.low ++
        } else if (cra[adaptLT] === "moderate") {
          adaptLTCount.moderate ++
        } else if (cra[adaptLT] === "high") {
          adaptLTCount.high ++
        } else if (cra[adaptLT] === "very_high") {
          adaptLTCount.veryHigh ++
        }
      });

      this.initialCRAList.forEach(cra => {
        if (cra[adaptST] === "very_low") {
          adaptSTCount.veryLow ++
        } else if (cra[adaptST] === "low") {
          adaptSTCount.low ++
        } else if (cra[adaptST] === "moderate") {
          adaptSTCount.moderate ++
        } else if (cra[adaptST] === "high") {
          adaptSTCount.high ++
        } else if (cra[adaptST] === "very_high") {
          adaptLTCount.veryHigh ++
        }
      });

      let finalDataset = [
        {
          label: 'Very Low',
          data: [riskSTCount.veryLow, riskLTCount.veryLow],
          backgroundColor: "rgba(40, 167, 69, 0.2)",
          stack: 'Risk',
        },
        {
          label: 'Low',
          data: [riskSTCount.low,riskLTCount.low],
          backgroundColor: "rgba(40, 167, 69, 0.5)",
          stack: 'Risk',
        },
        {
          label: 'Moderate',
          data: [riskSTCount.moderate, riskLTCount.moderate],
          backgroundColor: "rgba(255, 193, 7, 0.5)",
          stack: 'Risk',
        },
        {
          label: "High",
          backgroundColor: "rgba(255, 153, 0, 0.5)",
          data: [riskSTCount.high, riskLTCount.high],
          stack: 'Risk'
        },
        {
          label: "Very High",
          backgroundColor: "rgba(221, 75, 57, 0.5)",
          data: [riskSTCount.veryHigh, riskLTCount.veryHigh],
          stack: 'Risk'
        },
        {
          label: 'Adaptation Very Low',
          data: [
            adaptSTCount.veryLow || riskSTCount.veryLow,
            adaptLTCount.veryLow || riskLTCount.veryLow,
          ],
          backgroundColor: "#46d267",
          stack: 'Adaptation',
        },
        {
          label: 'Adaptation Low',
          data: [
            adaptSTCount.low || riskSTCount.low,
            adaptLTCount.low || riskLTCount.low,
          ],
          backgroundColor: "#28a745",
          stack: 'Adaptation',
        },
        {
          label: 'Adaptation Moderate',
          data: [
            adaptSTCount.moderate || riskSTCount.moderate,
            adaptLTCount.moderate || riskLTCount.moderate,
          ],
          backgroundColor: "#ffc107",
          stack: 'Adaptation',
        },
        {
          label: "Adaptation High",
          backgroundColor: "#FF9900",
          data: [
            adaptSTCount.high || riskSTCount.high,
            adaptLTCount.high || riskLTCount.high,
          ],
          stack: 'Adaptation'
        },
        {
          label: "Adaptation Very High",
          backgroundColor: "#dd4b39",
          data: [
            adaptSTCount.veryHigh || riskSTCount.veryHigh,
            adaptLTCount.veryHigh || riskLTCount.veryHigh,
          ],
          stack: 'Adaptation'
        },
      ]

      return finalDataset
    },
    riskPrePostAdaptData() {
      return {
        labels: ['Short Term', 'Long Term'],
        datasets: this.riskPrePostAdaptDataSet,
      }
    },
    hazardDataSet() {
      // initialize data
      const riskRatings = {
        no_rating: 0,
        very_low: 0,
        low: 0,
        moderate: 0,
        high: 0,
        very_high: 0,
      }
      const data = {
        bushfire: structuredClone(riskRatings),
        drought: structuredClone(riskRatings),
        flooding: structuredClone(riskRatings),
        heatwaves: structuredClone(riskRatings),
        rainfall: structuredClone(riskRatings),
        sea_level_rise: structuredClone(riskRatings),
        storms: structuredClone(riskRatings),
      }
      
      let field = "riskAssessmentRatingLTCode"
      if (this.selectedHazardChartTerm === "st") {
        field = "riskAssessmentRatingSTCode"
      }

      // populate data
      this.initialCRAList.forEach(cra => {
        const ratings = Object.keys(riskRatings)
        // remove first one "no_rating"
        ratings.shift()

        if (ratings.includes(cra[field])) {
          data[cra.climateHazardCode][cra[field]] ++
        } else {
          data[cra.climateHazardCode].no_rating ++
        }
      });

      const labelDetails = [
        { label: "No Rating", rating: "no_rating", backgroundColor: "#6C757D" },
        { label: "Very Low", rating: "very_low", backgroundColor: "#46D267" },
        { label: "Low", rating: "low", backgroundColor: "#28A745" },
        { label: "Moderate", rating: "moderate", backgroundColor: "#FFC107" },
        { label: "High", rating: "high", backgroundColor: "#FF9900" },
        { label: "Very High", rating: "very_high", backgroundColor: "#DD4B39" },
      ]
      const subAssets = [
        "bushfire",
        "drought",
        "flooding",
        "heatwaves",
        "rainfall",
        "sea_level_rise",
        "storms",
      ]

      const dataset = labelDetails.map(detail => {
        const tempData = subAssets.map(subAsset => {
          return data[subAsset][detail.rating]
        })

        return {
          label: detail.label,
          data: tempData,
          backgroundColor: detail.backgroundColor,
        }
      })

      return dataset
    },
    hazardData() {
      return {
        labels: [
          'Bushfire',
          'Drought',
          'Flooding',
          'Heatwaves',
          'Increased Rainfall Intensity',
          'Sea Level Rise',
          'Storms'
        ],
        datasets: this.hazardDataSet
      }
    },
    subAssetDataSet() {
      /* data for doughnut
      const data = {
        electrical: 0,
        exterior_enclosure: 0,
        fire_protection: 0,
        hvac: 0,
        interior_construction: 0,
        plumbing: 0,
        site_electrical_utilities: 0,
        site_improvement: 0,
        superstructure: 0,
      }

      this.initialCRAList.forEach(cra => {
        if (["moderate", "high", "very_high"].includes(cra[field])) {
          data[cra.subAssetTypeCode] ++
        }
      });

      return Object.values(data)
      */

      // initialize data
      const riskRatings = {
        no_rating: 0,
        very_low: 0,
        low: 0,
        moderate: 0,
        high: 0,
        very_high: 0,
      }
      const data = {
        electrical: structuredClone(riskRatings),
        exterior_enclosure: structuredClone(riskRatings),
        fire_protection: structuredClone(riskRatings),
        hvac: structuredClone(riskRatings),
        interior_construction: structuredClone(riskRatings),
        plumbing: structuredClone(riskRatings),
        site_electrical_utilities: structuredClone(riskRatings),
        site_improvement: structuredClone(riskRatings),
        superstructure: structuredClone(riskRatings),
        site_mechanical_utilities: structuredClone(riskRatings),
        conveying: structuredClone(riskRatings),
        roofing: structuredClone(riskRatings),
        rooftops: structuredClone(riskRatings),
        interior_finishes: structuredClone(riskRatings),
        car_parking: structuredClone(riskRatings),
        equipment: structuredClone(riskRatings),
      }

      let field = "riskAssessmentRatingLTCode"
      if (this.selectedSubAssetChartTerm === "st") {
        field = "riskAssessmentRatingSTCode"
      }

      // populate data
      this.initialCRAList.forEach(cra => {
        const ratings = Object.keys(riskRatings)
        // remove first one "no_rating"
        ratings.shift()

        if (data[cra.subAssetTypeCode]) {
          if (ratings.includes(cra[field])) {
            data[cra.subAssetTypeCode][cra[field]] ++
          } else {
            data[cra.subAssetTypeCode].no_rating ++
          }
        }
      });

      const labelDetails = [
        { label: "No Rating", rating: "no_rating", backgroundColor: "#6C757D" },
        { label: "Very Low", rating: "very_low", backgroundColor: "#46D267" },
        { label: "Low", rating: "low", backgroundColor: "#28A745" },
        { label: "Moderate", rating: "moderate", backgroundColor: "#FFC107" },
        { label: "High", rating: "high", backgroundColor: "#FF9900" },
        { label: "Very High", rating: "very_high", backgroundColor: "#DD4B39" },
      ]
      const subAssets = [
        "electrical",
        "exterior_enclosure",
        "fire_protection",
        "hvac",
        "interior_construction",
        "plumbing",
        "site_electrical_utilities",
        "site_improvement",
        "superstructure",
        "site_mechanical_utilities",
        "conveying",
        "roofing",
        "rooftops",
        "interior_finishes",
        "car_parking",
        "equipment",
      ]

      const dataset = labelDetails.map(detail => {
        const tempData = subAssets.map(subAsset => {
          return data[subAsset][detail.rating]
        })

        return {
          label: detail.label,
          data: tempData,
          backgroundColor: detail.backgroundColor,
        }
      })

      return dataset
    },
    subAssetData() {
      return {
        labels: [
          "Electrical",
          "Exterior Enclosure",
          "Fire Protection",
          "HVAC",
          "Interior Construction",
          "Plumbing",
          "Site Electrical Utilities",
          "Site Improvement",
          "Superstructure",
          "Site Mechanical Utilities",
          "Conveying",
          "Roofing",
          "Rooftops",
          "Interior Finishes",
          "Car Parking",
          "Equipment",
        ],
        /* dataset for doughnut
        datasets: [
          {
            backgroundColor: [
              "#F66D9B",
              "#9561E2",
              "#6574CD",
              "#E3352F",
              "#F6993F",
              "#FFED4A",
              "#38C171",
              "#4DC0B5",
              "#3490DC",
            ],
            data: this.subAssetDataSet,
          }
        ],
        */
        datasets: this.subAssetDataSet,
      }
    },
    hazardCombinedDataSet() {
      const backgroundColor = ['#FFEE4C', '#9660E6', '#FA9939', '#F66D9B', '#4BBFB1', '#328FDD', '#3EBF6E']
      const data = {
        bushfire: 0,
        drought: 0,
        flooding: 0,
        heatwaves: 0,
        rainfall: 0,
        sea_level_rise: 0,
        storms: 0,
      }
      let total = 0

      let field = "riskAssessmentRatingLTCode"
      if (this.selectedHazardCombinedChartTerm === "st") {
        field = "riskAssessmentRatingSTCode"
      }

      this.initialCRAList.forEach(cra => {
        if (["moderate", "high", "very_high"].includes(cra[field])) {
          data[cra.climateHazardCode] ++
          total ++
        }
      });

      return [
        {
          backgroundColor,
          data: Object.values(data).map(val => {
            if (total === 0) {
              return 0
            } else {
              return val
            }
          }),
        },
      ]
    },
    hazardCombinedData() {
      return {
        labels: [
          'Bushfire',
          'Drought',
          'Flooding',
          'Heatwaves',
          'Increased Rainfall Intensity',
          'Sea Level Rise',
          'Storms'
        ],
        datasets: this.hazardCombinedDataSet
      }
    },
    riskRatingChangesDataset() {
      const startFields = [
        "very_low_start",
        "low_start",
        "moderate_start",
        "high_start",
        "very_high_start",
      ]
      const endFields = [
        "very_low_end",
        "low_end",
        "moderate_end",
        "high_end",
        "very_high_end",
      ]

      // initialize data
      const data = []
      startFields.forEach(start => {
        const tempArr = endFields.map(end => {
          return {
            from: start, to: end, flow: 0
          }
        })

        data.push(...tempArr)
      })

      this.initialCRAList.forEach(cra => {
        const index = data.findIndex(datum => {
          return datum.from === `${cra.riskAssessmentRatingSTCode}_start`
            && datum.to === `${cra.riskAssessmentRatingLTCode}_end`
        })

        if (index !== -1) {
          data[index].flow += 1
        }
      });

      const finalData = []
      data.forEach(datum => {
        if (datum.flow) {
          finalData.push(datum)
        }
      })

      return finalData
    },
    riskRatingChangesData() {
      const colors = {
        very_low_start: "#46D267",
        very_low_end: "#46D267",
        low_start: "#28A745",
        low_end: "#28A745",
        moderate_start: "#FFC107",
        moderate_end: "#FFC107",
        high_start: "#FF9900",
        high_end: "#FF9900",
        very_high_start: "#DD4B39",
        very_high_end: "#DD4B39",
      };
      const getColor = (key) => colors[key];

      return  {
        datasets: [{
          data: this.riskRatingChangesDataset,
          colorFrom: (c) => getColor(c.dataset.data[c.dataIndex]?.from || 0),
          colorTo: (c) => getColor(c.dataset.data[c.dataIndex]?.to || 0),
          priority: {
            very_low_start: 0,
            very_low_end: 1,
            low_start: 2,
            low_end: 3,
            moderate_start: 4,
            moderate_end: 5,
            high_start: 6,
            high_end: 7,
            very_high_start: 8,
            very_high_end: 9,
          },
          labels: {
            very_low_start: "Very Low",
            very_low_end: "Very Low",
            low_start: "Low",
            low_end: "Low",
            moderate_start: "Moderate",
            moderate_end: "Moderate",
            high_start: "High",
            high_end: "High",
            very_high_start: "Very High",
            very_high_end: "Very High",
          }
        }
      ]}
    },
    hazardPieDataSet() {
      const backgroundColor = ['#FFEE4C', '#9660E6', '#FA9939', '#F66D9B', '#4BBFB1', '#328FDD', '#3EBF6E']
      const data = {
        moderate: {
          bushfire: 0,
          drought: 0,
          flooding: 0,
          heatwaves: 0,
          rainfall: 0,
          sea_level_rise: 0,
          storms: 0,
        },
        high: {
          bushfire: 0,
          drought: 0,
          flooding: 0,
          heatwaves: 0,
          rainfall: 0,
          sea_level_rise: 0,
          storms: 0,
        },
        very_high: {
          bushfire: 0,
          drought: 0,
          flooding: 0,
          heatwaves: 0,
          rainfall: 0,
          sea_level_rise: 0,
          storms: 0,
        },
      }

      this.initialCRAList.forEach(cra => {
        if (["moderate", "high", "very_high"].includes(cra.riskAssessmentRatingLTCode)) {
          data[cra.riskAssessmentRatingLTCode][cra.climateHazardCode] ++
        }
        if (["moderate", "high", "very_high"].includes(cra.riskAssessmentRatingSTCode)) {
          data[cra.riskAssessmentRatingSTCode][cra.climateHazardCode] ++
        }
      });

      return [
        {
          backgroundColor,
          data: Object.values(data.moderate),
        },
        {
          backgroundColor,
          data: Object.values(data.high),
        },
        {
          backgroundColor,
          data: Object.values(data.very_high),
        }
      ]
    },
    hazardPieData() {
      return {
        labels: [
          'Bushfire',
          'Drought',
          'Flooding',
          'Heatwaves',
          'Increased Rainfall Intensity',
          'Sea Level Rise',
          'Storms'
        ],
        datasets: this.hazardPieDataSet
      }
    },
    adaptationActionDataSet() {
      const data = {
        com_edu_eng: 0,
        eng_solutions: 0,
        hazard_investigation: 0,
        ops_management: 0,
        relocation_avoidance: 0,
      }

      this.initialCRAList.forEach(cra => {
        cra.adaptationActions?.forEach(adaptation => {
          if (!adaptation.is_implemented) {
            data[adaptation.adaptationCategoryCode] ++
          }
        })
      })

      return Object.values(data)
    },
    adaptationActionData() {
      return {
        labels: [
          "Communication, education and engagement",
          "Engineering solutions",
          "Hazard investigation",
          "Operational management",
          "Relocation and avoidance",
        ],
        datasets: [
          {
            backgroundColor: ['#FFEE4C', '#9660E6', '#FA9939', '#F66D9B', '#4BBFB1'],
            data: this.adaptationActionDataSet,
          }
        ]
      }
    },
    adaptationActionTable() {
      const implementationTiming = {
        "Very high priority (<1 year)": { implemented: 0, notImplemented: 0 } ,
        "High priority (1-5 years)": { implemented: 0, notImplemented: 0 } ,
        "Medium priority (5-10 year)": { implemented: 0, notImplemented: 0 } ,
        "Low priority (10-20 years)": { implemented: 0, notImplemented: 0 } ,
        "Very low priority (20+ years)": { implemented: 0, notImplemented: 0 } ,
      }

      this.initialCRAList?.forEach(cra => {
        cra.adaptationActions?.forEach(adaptation => {
          if(!implementationTiming[adaptation.implementation_timing]){
            return
          }
          if (adaptation.is_implemented) {
            implementationTiming[adaptation.implementation_timing].implemented ++
          } else {
            implementationTiming[adaptation.implementation_timing].notImplemented ++
          }
        })
      })

      const data = []
      Object.entries(implementationTiming).forEach(([key, value]) => {
        const total = value.implemented + value.notImplemented
        const percentage = value.implemented ? value.implemented / total * 100 : 0
        const tempData = {
          timing: key,
          count: total,
          implemented: value.implemented,
          percent: Math.round((percentage + Number.EPSILON) * 100) / 100,
        }

        data.push(tempData)
      })

      return data
    }
  },
  methods: {
    cellClass({ row, column }) {
      if (column.property === "percent") {
        if (row.percent < 30) {
          return "text-danger fw-bold"
        } else if (row.percent < 70) {
          return "text-warning fw-bold"
        } else {
          return "text-success fw-bold"
        }
      } else if (column.property === "count") { 
        return "fw-bold"
      } else if (column.property === "implemented") {
        return "fw-bold text-center"
      }
    }
  },
}
</script>

<style scoped>
.small-chart {
  max-width: 300px;
  margin:  8px auto;
}

.arc-primary-font{
  color: #0C0808
}
.arc-orange-font{
  color: #E4610F
}

.el-collapse {
  border: 0;
}

.adaptation-table-title {
  font-size: 15px;
  font-weight: bold;
  color: rgb(104, 104, 104);
}

.adaptation-table-card {
  height: 390px;
}

.timing-column {
  font-size: 12px;
}

.sub-asset-chart {
  width: 450px !important;
  max-width: 450px !important;
}

.sub-asset-chart-container {
  margin-left: 55px;
}
</style>