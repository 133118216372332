<template>
  <div class="pie-chart" ref="pieChartContainer"></div>
</template>

<script>
import { Chart, Title, Tooltip, Legend, ArcElement, PieController } from 'chart.js'

Chart.register(Title, Tooltip, Legend, ArcElement, PieController)

export default {
  name: 'PieChart',
  props: ["chartData", "chartOptions", "chartId"],
  watch: {
    chartData() {
      this.createChart()
    },
  },
  methods: {
    createChart() {
      if (document.getElementById(this.chartId)) {
        this.$refs.pieChartContainer.removeChild(document.getElementById(this.chartId))
      }
      
      const canvas = document.createElement('canvas');
      canvas.id = this.chartId;
      this.$refs.pieChartContainer.appendChild(canvas);

      const ctx = document.getElementById(this.chartId)
      new Chart(ctx, {
        type: 'pie',
        options: this.chartOptions,
        data: {
          datasets: this.chartData.datasets,
          labels: this.chartData.labels,
        },
      });
    }
  },
  mounted() {
    this.createChart()
  }
}
</script>