<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <el-row class="mt-2">
          <el-col>
            <el-card shadow="never"> 
              <el-row>
                <el-col :span="1" class="custom-icon-space">
                  <el-avatar :size="30" class="arc-light-orange-bg"> <el-icon><Service /></el-icon> </el-avatar>
                </el-col>
                <el-col :span="23">
                  <el-breadcrumb separator="/" class="mt-2">
                    <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                    <el-breadcrumb-item>Support</el-breadcrumb-item>
                  </el-breadcrumb>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        
      </el-header>
      <el-main class="mt-2">
        <el-card shadow="always">
          
          <el-row :gutter="20">
            <el-col :span="4"></el-col>
            <el-col :span="16" align="center">
              <el-text class="arc-orange-font" size="large"> <h2>Contact us</h2></el-text>
              <el-row class="m-5">
                <el-col :span="12">
                  <el-avatar class="arc-light-orange-bg"><el-icon size="large"><PhoneFilled /></el-icon></el-avatar>
                  <el-text class="arc-primary-font" size="large" tag="b"> +61 (0) 424 593 000</el-text>
                </el-col>
                <el-col :span="12">
                  <el-avatar class="arc-light-orange-bg"><el-icon size="large"><Message /></el-icon></el-avatar>
                  <el-text class="arc-primary-font" size="large" tag="b"> timothy.watson@arcadis.com </el-text>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="4"></el-col>
          </el-row>
        </el-card>
      </el-main>
      <el-footer class="">
        
      </el-footer>
    </el-container>
  </div>
  <!-- <el-empty description="Under Construction" /> -->
</template>

<script>
/*eslint-disable */
import {
  Service,
  PhoneFilled,
  Message 
} from '@element-plus/icons-vue'
export default {
  name: 'Support',
  components: {
    Service,
    PhoneFilled,
    Message 
  },
  // data() {
  //   return {
      
  //   }
  // },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}
.el-tabs--border-card>.el-tabs__header {
    background-color: #F0F0F0 !important
}
.custom-iframe-pdf {
  min-height: 77vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
</style>
  