<template>
  <div class="sankey-chart" ref="sankeyChartContainer"></div>
</template>
<script>

/*eslint-disable */
import { Chart } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';

Chart.register(SankeyController, Flow);

export default {
  name: 'SankeyChart',
  props: ["chartData", "chartOptions"],
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData() {
      this.createChart()
    },
  },
  methods: {
    createChart() {
      if (document.getElementById("sankey")) {
        this.$refs.sankeyChartContainer.removeChild(document.getElementById("sankey"))
      }
      
      const canvas = document.createElement('canvas');
      canvas.id = "sankey";
      this.$refs.sankeyChartContainer.appendChild(canvas);

      const ctx = document.getElementById("sankey")
      new Chart(ctx, {
        type: 'sankey',
        options: this.chartOptions,
        data: {
          datasets: this.chartData.datasets,
          labels: this.chartData.labels,
        },
      });
    }
  },
  mounted() {
    this.createChart()
  },
}
</script>

<style scoped>
.sankey-chart {
  height: 300px !important;
  max-height: 300px !important;
}
</style>
