/*eslint-disable*/
// import axios from 'axios';
// import {baseApiUrl} from "../../../config.js";
// import { loadModules } from 'esri-loader';

const state = {
  esriLoginStatus: false,
  esriPortalObject: {},
  mapWebScene: null,
  mapView: null,
  userEmail: null,
  user: null,
  mapWebSceneLoadFlag: null,
  isAddNewDiscussion: false
}

function initialState() {
  return {
    esriLoginStatus: false,
    esriPortalObject: {},
    mapWebScene: null,
    mapView: null,
    userEmail: null,
    user: null,
    mapWebSceneLoadFlag: null,
    isAddNewDiscussion: false
  }
}
const mutations = {
  setESRILoginStatus(state, data) {
    state.esriLoginStatus = data
  },
  setESRIPortalObject(state, data) {
    state.esriPortalObject = data
  },
  saveMapWebScene(state, data) {
    state.mapWebScene = data
  },
  saveMapView(state, data) {
    state.mapView = data
  },
  saveUserEmail(state, data) {
    state.userEmail = data
  },
  saveUser(state, data) {
    state.user = data
  },
}

const actions = {

}

const getters = {}



export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
