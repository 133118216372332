<template>
  <el-scrollbar max-height="95dvh">
    <div class="common-layout">
      <el-container>
        <el-header>
          <el-row class="mt-2">
            <el-col>
              <el-card shadow="never"> 
                <el-row>
                  <el-col :span="1" class="custom-icon-space">
                    <el-avatar :size="30" class="arc-light-orange-bg"> <el-icon><Suitcase /></el-icon> </el-avatar>
                  </el-col>
                  <el-col :span="23">
                    <el-breadcrumb separator="/" class="mt-2">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Portfolio Assessment</el-breadcrumb-item>
                    </el-breadcrumb>
                  </el-col>
                </el-row>
                
              </el-card>
            </el-col>
          </el-row>
          
        </el-header>
        <el-main class="mt-2">
          <el-tabs type="border-card" tab-position="bottom" >
            <el-tab-pane>
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-earth-americas"></i>
                  <el-text tag="b" class="arc-primary-font"> Climate Hazard Explorer</el-text>
                </span>
              </template>

              <!-- <template v-if="sceneId">
                <FlexMap mode="3d" :mapSceneId="sceneId" id="assetContextMap"/>
              </template>
              <template v-else>
                <FlexMap mode="2d" :mapSceneId="mapId" id="assetContextMap"/>
              </template> -->
              <iframe src="https://aus.gis.arcadis.com/portal/apps/dashboards/ab3f18b773a546d4ac29bacaacc1f5e3"
                class="custom-iframe-gis"></iframe>
              
            </el-tab-pane>
            <el-tab-pane>
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-square-poll-vertical"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk Model Dashboard</el-text>
                </span>
              </template>

              <iframe :src="dashboardURL"
              class="custom-iframe-powerbi"
              ></iframe>

            </el-tab-pane>
            <el-tab-pane>
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-table"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk and Adaptation Register</el-text>
                </span>
              </template>
              <el-card shadow="never" class="mb-3">
                <CampusDashboard
                  :initialCRAList="initialCRAList"
                />
              </el-card>
              <el-card shadow="never" class="mb-3">
                <CampusAssessmentFilter
                  @changeFilter="handleOnChangeFilter"
                  :initialAdvanceFilters="advanceFilters"
                />
              </el-card>
              <el-tabs
                v-model="activeRegisterTab"
                tab-position="left"
              >
                <el-tab-pane label="Risk" name="riskTab">
                  <RiskTable
                    @fetchCRA="handleOnFetchCRA"
                    :initialCRAList="initialCRAList"
                    :initialLoadstate="initialLoadstate"
                    :type="'portfolio'"
                  />
                </el-tab-pane>
                <el-tab-pane label="Adaptation" name="adaptationTab">
                  <AdaptationTable
                    @fetchCRA="handleOnFetchCRA"
                    :initialCRAList="initialCRAList"
                    :initialCampusLabel="''"
                    :initialLoadstate="initialLoadstate"
                    :type="'portfolio'"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-main>
        <el-footer class="">
        </el-footer>
      </el-container>
    </div>
  </el-scrollbar>
</template>

<script>
/*eslint-disable */
import { Suitcase } from '@element-plus/icons-vue'
import FlexMap from './FlexMap.vue'
import AdaptationTable from './AdaptationTable.vue'
import RiskTable from './RiskTable.vue'
import { ref } from 'vue'
import axios from 'axios';
import CampusDashboard from './CampusDashboard.vue'
import { ElNotification } from 'element-plus'
import { baseApiUrl } from "../../config.js";
import CampusAssessmentFilter from './CampusAssessmentFilter.vue'

export default {
  name: 'Portfolio',
  components: {
    Suitcase,
    FlexMap,
    RiskTable,
    CampusDashboard,
    AdaptationTable,
    CampusAssessmentFilter,
  },
  data() {
    return {
      activeRegisterTab: ref('riskTab'),
      portfolioList: [
        {
          ccImpact: 'Cumulative climate change impact',
          buildingElement: 'Demand managemnet system',
          riskStatement: '',
          consequenceArea: 'Operating',
          currentControls: '',
          workshopComments: '',
          riskType: 'Acute',
          riskAssessmentLikelihoodNFCode: 'Almost Certain',
          riskAssessmentLikelihoodMFCode: 'Almost Certain',
          riskAssessmentLikelihoodFFCode: '',
          riskAssessmentConsequenceNFCode: 'Neglible',
          riskAssessmentConsequenceMFCode: 'Minor',
          riskAssessmentConsequenceFFCode: '',
          riskRatingNFCode: '',
          riskRatingMFCode: '',
          riskRatingFFCode: '',
        },
        {
          ccImpact: 'Cumulative climate change impact',
          buildingElement: 'Demand managemnet system',
          riskStatement: '',
          consequenceArea: 'Operating',
          currentControls: '',
          workshopComments: '',
          riskType: 'Acute',
          riskAssessmentLikelihoodNFCode: 'Almost Certain',
          riskAssessmentLikelihoodMFCode: 'Almost Certain',
          riskAssessmentLikelihoodFFCode: '',
          riskAssessmentConsequenceNFCode: 'Neglible',
          riskAssessmentConsequenceMFCode: 'Minor',
          riskAssessmentConsequenceFFCode: '',
          riskRatingNFCode: '',
          riskRatingMFCode: '',
          riskRatingFFCode: '',
        },
        {
          ccImpact: 'Cumulative climate change impact',
          buildingElement: 'Demand managemnet system',
          riskStatement: '',
          consequenceArea: 'Operating',
          currentControls: '',
          workshopComments: '',
          riskType: 'Acute',
          riskAssessmentLikelihoodNFCode: 'Almost Certain',
          riskAssessmentLikelihoodMFCode: 'Almost Certain',
          riskAssessmentLikelihoodFFCode: '',
          riskAssessmentConsequenceNFCode: 'Neglible',
          riskAssessmentConsequenceMFCode: 'Minor',
          riskAssessmentConsequenceFFCode: '',
          riskRatingNFCode: '',
          riskRatingMFCode: '',
          riskRatingFFCode: '',
        },
      ],
      perPage: 2,
      currentPage: 1,
      rawCRAList: [],
      initialLoadstate: false,
      advanceFilters: {
        selectedAssetLevel2: "all",
        selectedClimateHazard: "all",
        selectedConsequence: "all",
        selectedRiskType: "all",
        selectedSTConsequence: "all",
        selectedSTLikelihood: "all",
        selectedSTRating: "all",
        selectedLTConsequence: "all",
        selectedLTLikelihood: "all",
        selectedLTRating: "all",
      },
    }
  },
  computed: {
    sceneId: {
      get() {
        return this.$store.state.mainModule.assessmentMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentAssessment)[0].sceneId
      }
    },
    mapId: {
      get() {
        return this.$store.state.mainModule.assessmentMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentAssessment)[0].mapId
      }
    },
    currentAssessment: {
      get() {
        return this.$store.state.mainModule.currentAssessment
      }
    },
    dashboardURL: {
      get() {
        return this.$store.state.mainModule.assessmentMapScenes.filter(obj=>obj.code == this.$store.state.mainModule.currentAssessment)[0].climateDashboardURL
      }
    },
    initialCRAList: {
      get() {
        let filteredList = this.rawCRAList;

        if (this.advanceFilters.selectedClimateHazard !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedClimateHazard === cra.climateHazardCode
          )
        }
        if (this.advanceFilters.selectedAssetLevel2 !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedAssetLevel2 === cra.subAssetTypeCode
          )
        }
        if (this.advanceFilters.selectedConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedConsequence === cra.consequenceCategoryCode
          )
        }
        if (this.advanceFilters.selectedRiskType !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedRiskType === cra.riskTypeCode
          )
        }
        // Short term
        if(this.advanceFilters.selectedSTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTConsequence === cra.riskAssessmentConsequenceSTCode
          )
        }
        if(this.advanceFilters.selectedSTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTLikelihood === cra.riskAssessmentLikelihoodSTCode
          )
        }
        if(this.advanceFilters.selectedSTRating !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTRating === cra.riskAssessmentRatingSTCode
          )
        }
        // Long term
        if(this.advanceFilters.selectedLTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTConsequence === cra.riskAssessmentConsequenceLTCode
          )
        }
        if(this.advanceFilters.selectedLTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTLikelihood === cra.riskAssessmentLikelihoodLTCode
          )
        }
        if(this.advanceFilters.selectedLTRating !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTRating === cra.riskAssessmentRatingLTCode
          )
        }

        return filteredList
      }
    },
  },
  methods: {
    handleOnFetchCRA: function() {
      const current = this
      current.initialLoadstate = true

      const url = baseApiUrl + 'portfolioClimateRiskEntries'

      axios.get(url).then(response=>{
        if (response.data) {
          current.rawCRAList = response.data
        }
      }).catch(() => {
        ElNotification({
          type: 'error',
          message: 'Failed to get data...',
          title: 'ERROR'
        })
      }).finally(() => {
        current.initialLoadstate = false
      })
    },
    handleOnChangeFilter: function({ field, value }) {
      this.advanceFilters[field] = value
    }
  },
  beforeMount(){
    const current = this
    let currentAssessmentItem = current.$route.path.replace("/", "")
    this.$store.commit('mainModule/setCurrentAssessment', currentAssessmentItem)
    this.handleOnFetchCRA()
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}

</style>
<style>
.el-tabs--border-card>.el-tabs__header {
    background-color: #F0F0F0 !important
}
.el-tabs__item:hover {
    color: #E4610F !important;
}
.el-tabs__item.is-active {
    color: #E4610F !important;
}
.el-tabs__active-bar {
    background-color: #E4610F !important;
}
.custom-iframe-gis {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
</style>
  