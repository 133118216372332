import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue3  from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router.js"
import '@fortawesome/fontawesome-free/js/all'
import '@fortawesome/fontawesome-free/css/all.css'
import { store } from "./state/modules/store";

let app = createApp(App)
app.use(BootstrapVue3)
app.use(ElementPlus)
app.use(store)
app.use(router)
app.mount('#app')
