/* eslint-disable */
var baseApiUrl = ''
var appBaseUrl = ''
if (process.env.NODE_ENV === 'production') {
  baseApiUrl = 'https://tafe-raap-api.geodemosolutions.com/public/'
  appBaseUrl = 'https://tafe-raap.geodemosolutions.com/#/'
 }
else {
  baseApiUrl = 'http://localhost/tafe-raap-api/public/'
  appBaseUrl = 'http://localhost:7700/#/'
}

export {
  baseApiUrl,
  appBaseUrl
}
