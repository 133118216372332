<template>
  <!-- <el-tag class="ml-2" type="warning" size="large">{{ campusLabel }}</el-tag> -->
  <el-table 
    :data="pageSelect"  
    header-cell-class-name="custom-tbl-header"
    size="small"
    :height="windowHeight*screenFactor"
    style="width: 100%"
    v-loading="loadState"
  >
    <!-- <el-table-column type="index" width="50" align="center"/> -->
    <el-table-column prop="climateHazardLabel" label="Climate Hazard" width="120"  v-loading="loadState">
    </el-table-column>
    <el-table-column prop="subAssetTypeLabel" label="Asset Level 2" width="125" >
    </el-table-column>
    <el-table-column prop="risk_statement" label="Risk Statement" width="230" >
    </el-table-column>
    <el-table-column prop="consequenceCategoryLabel" label="TAFE NSW Consequence Area" width="120" >
    </el-table-column>
    <el-table-column prop="assumed_controls" label="Assumed Controls" width="200" >
    </el-table-column>
    <el-table-column prop="riskTypeLabel" label="Risk Type" width="100" v-if="type !== 'region'">
    </el-table-column>
    <el-table-column prop="criticalCampusLabels" label="Critical Campuses" width="100" v-else>
    </el-table-column>
    <el-table-column label="Risk assessment" align="center">
      <el-table-column label="Short term" align="center">
        <el-table-column prop="riskAssessmentLikelihoodSTLabel" label="Likelihood" width="120">
        </el-table-column>
        <el-table-column prop="riskAssessmentConsequenceSTLabel" label="Consequence" width="120">
        </el-table-column>
        <el-table-column prop="riskAssessmentRatingSTLabel" label="Risk Rating" width="120">
          <template #default="scope">
            <el-tag
              class="ml-2"
              type="danger"
              effect="dark"
              v-if="scope.row.riskAssessmentRatingSTCode == 'very_high'"
            >
              {{ scope.row.riskAssessmentRatingSTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="danger"
              v-if="scope.row.riskAssessmentRatingSTCode == 'high'"
            >
              {{ scope.row.riskAssessmentRatingSTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="warning"
              effect="dark"
              v-if="scope.row.riskAssessmentRatingSTCode == 'moderate'"
            >
              {{ scope.row.riskAssessmentRatingSTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="success"
              v-if="scope.row.riskAssessmentRatingSTCode == 'low'"
            >
              {{ scope.row.riskAssessmentRatingSTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="success"
              effect="dark"
              v-if="scope.row.riskAssessmentRatingSTCode == 'very_low'"
            >
              {{ scope.row.riskAssessmentRatingSTLabel }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <el-table-column prop="riskAssessmentLikelihoodLTLabel" label="Likelihood" width="120">
        </el-table-column>
        <el-table-column prop="riskAssessmentConsequenceLTLabel" label="Consequence" width="120">
        </el-table-column>
        <el-table-column prop="riskAssessmentRatingLTLabel" label="Risk Rating" width="120">
          <template #default="scope">
            <el-tag
              class="ml-2"
              type="danger"
              effect="dark"
              v-if="scope.row.riskAssessmentRatingLTCode == 'very_high'"
            >
              {{ scope.row.riskAssessmentRatingLTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="danger"
              v-if="scope.row.riskAssessmentRatingLTCode == 'high'"
            >
              {{ scope.row.riskAssessmentRatingLTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="warning"
              effect="dark"
              v-if="scope.row.riskAssessmentRatingLTCode == 'moderate'"
            >
              {{ scope.row.riskAssessmentRatingLTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="success"
              v-if="scope.row.riskAssessmentRatingLTCode == 'low'"
            >
              {{ scope.row.riskAssessmentRatingLTLabel }}
            </el-tag>
            <el-tag
              class="ml-2"
              type="success"
              effect="dark"
              v-if="scope.row.riskAssessmentRatingLTCode == 'very_low'"
            >
              {{ scope.row.riskAssessmentRatingLTLabel }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
    <el-table-column prop="prop_adaptation_action" label="Proposed Adaptation Actions" width="140" fixed="right">
        <template #default="scope">
            <template v-if="scope.row.adaptationActions.length == 0">
                <el-button class="m-1" type="primary" plain size="small"  title="Add New Adaptation Actions" @click="addAdaptationAction(scope.row.id, 'short')"><i class="fa fa-plus"></i></el-button>
            </template>
            <template v-else>
                <el-space wrap  v-for="adaptIdx in Math.ceil(scope.row.adaptationActions.length / 3)" :key="adaptIdx">
                    <div v-for="(adaptItem, adaptIndex) in scope.row.adaptationActions.slice(3 * (adaptIdx - 1), 3 * adaptIdx)" :key="adaptIndex">
                    <el-button plain class="m-1" :type="adaptItem.is_implemented?'success':'warning'" size="small"  title="Adaptation Action Details" @click="viewAdaptationAction(scope.row.id,adaptIndex + 3 * (adaptIdx - 1) + 1,adaptItem)">{{ adaptIndex + 3 * (adaptIdx - 1) + 1 }}</el-button>
                    <template v-if="(scope.row.adaptationActions.length == (adaptIndex + 3 * (adaptIdx - 1) + 1) && scope.row.adaptationActions.length < 9)">
                        <el-button plain class="m-1" type="primary" size="small"  title="Add New Adaptation Actions" @click="addAdaptationAction(scope.row.id, 'short')"><i class="fa fa-plus"></i></el-button>
                    </template>
                    </div>
                </el-space>
            </template>
        </template>
    </el-table-column>
    <el-table-column label="Risk treatment" align="center" fixed="right">
      <el-table-column label="Short term" align="center">
        <!-- <el-table-column prop="prop_adaptation_action_st" label="Proposed Adaptation Actions" width="140">
            <template #default="scope">
                <template v-if="scope.row.adaptationActionsST.length == 0 && scope.row.adaptationRiskRatingSTId">
                    <el-button class="m-1" type="primary" plain size="small"  title="Add New Adaptation Actions" @click="addAdaptationAction(scope.row.adaptationActionSTId, 'short')"><i class="fa fa-plus"></i></el-button>
                </template>
                <template v-else>
                    <el-space wrap  v-for="adaptIdx in Math.ceil(scope.row.adaptationActionsST.length / 3)" :key="adaptIdx">
                        <div v-for="(adaptItem, adaptIndex) in scope.row.adaptationActionsST.slice(3 * (adaptIdx - 1), 3 * adaptIdx)" :key="adaptIndex">
                        <el-button plain class="m-1" :type="adaptItem.is_implemented?'success':'warning'" size="small"  title="Adaptation Action Details" @click="viewAdaptationAction(scope.row.adaptationActionSTId,adaptIndex + 3 * (adaptIdx - 1) + 1,adaptItem)">{{ adaptIndex + 3 * (adaptIdx - 1) + 1 }}</el-button>
                        <template v-if="(scope.row.adaptationActionsST.length == (adaptIndex + 3 * (adaptIdx - 1) + 1) && scope.row.adaptationActionsST.length < 9)">
                            <el-button plain class="m-1" type="primary" size="small"  title="Add New Adaptation Actions" @click="addAdaptationAction(scope.row.adaptationActionSTId, 'short')"><i class="fa fa-plus"></i></el-button>
                        </template>
                        </div>
                    </el-space>
                </template>
            </template>
        </el-table-column> -->
        <el-table-column prop="adaptationLikelihoodSTLabel" label="Likelihood" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.adaptationLikelihoodSTCode" 
              v-if="scope.row.isEdit" 
              placeholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="adaptationConsequenceSTLabel" label="Consequence" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.adaptationConsequenceSTCode" 
              v-if="scope.row.isEdit" 
              placeholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="adaptationRiskRatingSTLabel" label="Risk Rating" width="120">
            <template #default="scope">
            <el-tag class="ml-2" type="danger" effect="dark" v-if="scope.row.adaptationRiskRatingSTCode == 'very_high'">{{ scope.row.adaptationRiskRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="danger" v-if="scope.row.adaptationRiskRatingSTCode == 'high'">{{ scope.row.adaptationRiskRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="warning" effect="dark" v-if="scope.row.adaptationRiskRatingSTCode == 'moderate'">{{ scope.row.adaptationRiskRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" v-if="scope.row.adaptationRiskRatingSTCode == 'low'">{{ scope.row.adaptationRiskRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" effect="dark" v-if="scope.row.adaptationRiskRatingSTCode == 'very_low'">{{ scope.row.adaptationRiskRatingSTLabel }}</el-tag>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <!-- <el-table-column prop="prop_adaptation_action_lt" label="Proposed Adaptation Actions" width="140">
            <template #default="scope">
                <template v-if="scope.row.adaptationActionsLT.length == 0 && scope.row.adaptationRiskRatingLTId">
                    <el-button class="m-1" type="primary" plain size="small"  title="Add New Adaptation Actions" @click="addAdaptationAction(scope.row.adaptationActionLTId, 'long')"><i class="fa fa-plus"></i></el-button>
                </template>
                <template v-else>
                    <el-space wrap  v-for="adaptIdx in Math.ceil(scope.row.adaptationActionsLT.length / 3)" :key="adaptIdx">
                        <div v-for="(adaptItem, adaptIndex) in scope.row.adaptationActionsLT.slice(3 * (adaptIdx - 1), 3 * adaptIdx)" :key="adaptIndex">
                        <el-button plain class="m-1" :type="adaptItem.is_implemented?'success':'warning'" size="small"  title="Adaptation Action Details" @click="viewAdaptationAction(scope.row.adaptationActionLTId,adaptIndex + 3 * (adaptIdx - 1) + 1,adaptItem)">{{ adaptIndex + 3 * (adaptIdx - 1) + 1 }}</el-button>
                        <template v-if="(scope.row.adaptationActionsLT.length == (adaptIndex + 3 * (adaptIdx - 1) + 1) && scope.row.adaptationActionsLT.length < 9)">
                            <el-button plain class="m-1" type="primary" size="small"  title="Add New Adaptation Actions" @click="addAdaptationAction(scope.row.adaptationActionLTId, 'long')"><i class="fa fa-plus"></i></el-button>
                        </template>
                        </div>
                    </el-space>
                </template>
            </template>
        </el-table-column> -->
        <el-table-column prop="adaptationLikelihoodLTLabel" label="Likelihood" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.adaptationLikelihoodLTCode" 
              v-if="scope.row.isEdit" 
              laceholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="adaptationConsequenceLTLabel" label="Consequence" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.adaptationConsequenceLTCode" 
              v-if="scope.row.isEdit" 
              laceholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="adaptationRiskRatingLTLabel" label="Risk Rating" width="120">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" effect="dark" v-if="scope.row.adaptationRiskRatingLTCode == 'very_high'">{{ scope.row.adaptationRiskRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="danger" v-if="scope.row.adaptationRiskRatingLTCode == 'high'">{{ scope.row.adaptationRiskRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="warning" effect="dark" v-if="scope.row.adaptationRiskRatingLTCode == 'moderate'">{{ scope.row.adaptationRiskRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" v-if="scope.row.adaptationRiskRatingLTCode == 'low'">{{ scope.row.adaptationRiskRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" effect="dark" v-if="scope.row.adaptationRiskRatingLTCode == 'very_low'">{{ scope.row.adaptationRiskRatingLTLabel }}</el-tag>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
    <el-table-column width="115" fixed="right" align="center">
      <template #header>
        Actions
      </template>
      <template #default="scope">
        <el-tooltip content="Edit Details" placement="bottom" effect="light">
          <!-- <el-button type="primary" link @click="editItem(scope.row)" >
            <i class="fa fa-edit large"></i>
          </el-button> -->
          <el-button type="primary" size="small" @click="editItem(scope.row)"  circle><i class="fa fa-edit large"></i></el-button>
        </el-tooltip>
        <el-tooltip content="Save Details" placement="bottom" v-if="scope.row.isEdit" effect="light">
          <!-- <el-button type="success" link @click="saveItem(scope.row)">
            <i class="fa fa-save large"></i>
          </el-button> -->
          <el-button type="success" size="small" @click="saveItem(scope.row)" circle><i class="fa fa-save large"></i></el-button>
        </el-tooltip>
        <el-tooltip content="Delete" placement="bottom" effect="light">
          <!-- <el-button type="danger" link @click="deleteItem(scope.row)">
            <i class="fa fa-trash large"></i>
          </el-button> -->
          <el-button type="danger" size="small" @click="deleteItem(scope.row)" circle><i class="fa fa-trash large"></i></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <el-row>
    <el-col :span="12" >
      
      <el-pagination
        background
        small
        :page-size="parseInt(perPage ? perPage : 1)"
        layout="total, prev, pager, next"
        :total="craList.length"
        :default-current-page="1"
        @current-change="selectedPage"
        style="margin-top:1rem"
      />
    </el-col>
    <el-col :span="12" align="right">
      <!-- <el-tooltip content="Add New Risk Entry" placement="bottom" effect="light">
        <el-button color="#E4610F" class="mt-2" size="large" @click="dialogAddCampusCRAEntry = true" circle><i class="fa fa-plus"></i></el-button>
      </el-tooltip> -->
      <el-tooltip content="Download" placement="bottom" effect="light">
        <el-button @click="handleOnclickDownload" type="primary" class="mt-2" size="large" circle><i class="fa-solid fa-download"></i></el-button>
      </el-tooltip>
    </el-col>
    
  </el-row>
  
  <el-dialog v-model="dialogAddAdaptationAction" title="" draggable >
    <template #header>
      <h4>Adaptation Action</h4>
    </template>
    <el-form :model="actionForm" label-width="auto" label-position="left" size="small">

      <el-form-item label="Climate Hazard">
        <!-- <el-input v-model="actionForm.climateHazard" disabled/> -->
        <el-select v-model="actionForm.climateHazardCode" placeholder="Select" size="small" disabled>
          <el-option
            v-for="item in climateHazardsList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Asset Level 2">
        <!-- <el-input v-model="actionForm.assetLevel2" disabled/> -->
        <el-select v-model="actionForm.assetLevel2Code" placeholder="Select" size="small" disabled>
          <el-option
            v-for="item in subAssetTypesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Measure">
        <el-select v-model="actionForm.measureCode" placeholder="Select" filterable>
          <el-option
            v-for="item in adaptationMeasuresList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Measure description">
        <el-input v-model="actionForm.measureDesc" type="textarea"/>
      </el-form-item>

      <el-form-item label="Adaptation Category">
        <el-select v-model="actionForm.adaptationCategoryCode" placeholder="Select">
          <el-option
            v-for="item in adaptationCategoriesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      
      <el-form-item label="Adaptation Type">
        <el-select v-model="actionForm.adaptationTypeCode" placeholder="Select">
          <el-option
            v-for="item in adaptationTypesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Owner Responsible">
        <el-input v-model="actionForm.ownerResponsible" />
      </el-form-item>
      <!-- <el-form-item label="Proposed adaptation action details">
        <el-input v-model="actionForm.action" type="textarea" />
      </el-form-item> -->
      <el-form-item label="Implementation timing">
        <el-select v-model="actionForm.implementationTiming" placeholder="Select">
          <el-option
            v-for="(item, itemIndex) in implementationTimingSet"
            :key="itemIndex"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      
      <el-form-item label="Timeline" v-if="type === 'region'">
        <el-select v-model="actionForm.timeline" placeholder="Select">
          <el-option
            v-for="(item, itemIndex) in timelineSet"
            :key="itemIndex"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Adaptation / Control" v-if="type === 'region'">
        <el-select v-model="actionForm.adaptationControl" placeholder="Select">
          <el-option
            v-for="(item, itemIndex) in adaptationControlSet"
            :key="itemIndex"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="Feasibility">
        <el-input-number
          v-model="actionForm.feasibility"
          :min="0"
          :max="5"
          size="small"
          controls-position="right"
        />
      </el-form-item>
      <el-form-item label="Indicative cost">
        <el-input-number
          v-model="actionForm.indicativeCost"
          :min="0"
          :max="5"
          size="small"
          controls-position="right"
        />
      </el-form-item>
      <el-form-item label="Impact">
        <el-input-number
          v-model="actionForm.impact"
          :min="0"
          :max="5"
          size="small"
          controls-position="right"
        />
      </el-form-item>

      <el-form-item label="Mean" >
        <el-tag class="ml-2" type="success">{{ actionForm.mean }}</el-tag>
      </el-form-item>

      <el-form-item label="Has the action been implemented?">
        <el-switch v-model="actionForm.isImplemented" />
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogAddAdaptationAction = false" color="#E4610F" plain>Cancel</el-button>
        <el-button @click="onSubmit" color="#E4610F">
          Save
        </el-button>
      </span>
    </template>
  </el-dialog>
  
</template>

<script>
/*eslint-disable */
import { ElMessage, ElMessageBox } from "element-plus";
import { baseApiUrl } from "../../config.js";
import { ElNotification } from 'element-plus'
import axios from 'axios';
import {
  Check,
  Delete,
  Edit
} from '@element-plus/icons-vue'
export default {
  name: 'AdaptationTable',
  components: {
    Check,
    Delete,
    Edit
  },
  emits: ["fetchCRA"],
  props: ["initialCRAList", "initialCampusLabel", "initialLoadstate", "type"],
  data() {
    return {
      actionForm: {
        craAdaptationActionDetailsId: null,
        craID: null,
        climateHazardCode: "",
        assetLevel2Code: "",
        measureCode: "",
        measureDesc: "",
        feasibility: 0,
        indicativeCost: 0,
        impact: 0,
        mean: 0,
        adaptationCategoryCode: "",
        adaptationTypeCode: "",
        isImplemented: 0,
        // craAdaptationActionId: null,
        // adaptActionTypeCode: "",
        // action: "",
        ownerResponsible: "",
        implementationTiming: "",
        timeline: "",
        adaptationControl: "",
      },
      windowHeight: 0,
      screenFactor: 0.55,
      dialogAddAdaptationAction: false,
      loadState: false,
      selectedCampus: null,
      campusLabel: null,
      craList: [],
      perPage: 20,
      currentPage: 1,
      implementationTimingSet: [
        'Very high priority (<1 year)',
        'High priority (1-5 years)',
        'Medium priority (5-10 year)',
        'Low priority (10-20 years)',
        'Very low priority (20+ years)'
      ],
      timelineSet: [
        '0 -1 year',
        '2 - 5 years',
        '5+ years',
      ],
      adaptationControlSet: [
        'Adaptation',
        'Control',
      ],
    }
  },
  // watch: {
  //   selectedCampus() {
  //     if (this.selectedCampus) {
  //         this.changeFilter()
  //     }
  //   },
  // },
  computed: {
    campusList: {
        get() {
            return this.$store.state.mainModule.campusList
        }
    },
    currentCampusCode: {
        get() {
            return this.$store.state.mainModule.currentCampus?this.$store.state.mainModule.currentCampus:"albury"
        }
    },
    currentRegionCode: {
      get() {
        return this.$store.state.mainModule.currentRegion || "north"
      }
    },
    currentAssessment: {
        get() {
            return this.$store.state.mainModule.currentAssessment
        }
    },
    climateHazardsList: {
        get() {
            return this.$store.state.mainModule.climateHazardsList
        }
    },
    subAssetTypesList: {
        get() {
            return this.$store.state.mainModule.subAssetTypesList
        }
    },
    consequenceCategoriesList: {
        get() {
            return this.$store.state.mainModule.consequenceCategoriesList
        }
    },
    riskTypesList: {
        get() {
            return this.$store.state.mainModule.riskTypesList
        }
    },
    assessmentLikelihoodList: {
        get() {
            return this.$store.state.mainModule.assessmentLikelihoodList
        }
    },
    assessmentConsequencesList: {
        get() {
            return this.$store.state.mainModule.assessmentConsequencesList
        }
    },
    adaptationTypesList: {
        get() {
            return this.$store.state.mainModule.adaptationTypesList
        }
    },
    adaptationCategoriesList: {
        get() {
            return this.$store.state.mainModule.adaptationCategoriesList
        }
    },
    adaptationCurrentMeasureIDs: {
        get() {
            let currentCRAID = this.actionForm.craID
            let currentSubAsset = this.craList.filter(obj=>obj.id == currentCRAID)[0]?.subAssetTypeCode
            let currentHazard= this.craList.filter(obj=>obj.id == currentCRAID)[0]?.climateHazardCode
            if (this.type === "region") {
              return this.coreAdaptationActionsList
                .filter(obj => obj.climateHazardCode == currentHazard)
                .map(a => a.adaptation_measure_id)
            } else{
              return this.coreAdaptationActionsList
                .filter(obj=>obj.subAssetTypeCode == currentSubAsset  && obj.climateHazardCode == currentHazard)
                .map(a => a.adaptation_measure_id)
            }
        }
    },
    adaptationMeasuresList: {
        get() {
            const current = this
            let tempAdaptation = []
            if (current.type === 'region') {
              tempAdaptation = this.$store.state.mainModule.regionAdaptationMeasuresList
            } else {
              tempAdaptation = this.$store.state.mainModule.campusAdaptationMeasuresList
            }

            if(current.adaptationCurrentMeasureIDs.length > 0){
              return tempAdaptation.filter(item=>current.adaptationCurrentMeasureIDs.includes(item.id));
            }
            else {
              return tempAdaptation
            }
        }
    },
    coreAdaptationActionsList: {
        get() {
            if (this.type === "region") {
                return this.$store.state.mainModule.regionCoreAdaptationActionsList
            }
            return this.$store.state.mainModule.campusCoreAdaptationActionsList
        }
    },
    pageSelect: function() {
      let pageList = [];
      let newFilteredList = [].concat(this.craList);
      // let newFilteredList = structuredClone(this.craList);
      let itemsPerPage = this.perPage ? this.perPage : 1
      for (let i = 0; i < this.craList.length / itemsPerPage; i++) {
        pageList.push(newFilteredList.splice(0, itemsPerPage));
      }
      return pageList[this.currentPage - 1];
    },
  },
  watch: {
    'craList': {
      handler (newValue, oldValue) {
        newValue.forEach((item) => {
              if (item.riskAssessmentLikelihoodSTCode && item.riskAssessmentConsequenceSTCode) {
                  if (item.riskAssessmentLikelihoodSTCode == 'almost_certain') {
                      if (['major','severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_high') {
                              item.riskAssessmentRatingSTCode = 'very_high'
                              item.riskAssessmentRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['minor','medium'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  } 
                  else if (item.riskAssessmentLikelihoodSTCode == 'rare') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"

                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible','minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'unlikely') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'likely') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_high') {
                              item.riskAssessmentRatingSTCode = 'very_high'
                              item.riskAssessmentRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      }
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'possible') {
                      if (['medium', 'major', 'severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      } 
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
              }
              else {
                  if (item.riskAssessmentRatingSTCode) {
                      item.riskAssessmentRatingSTCode = ''
                      item.riskAssessmentRatingSTLabel = ''
                  }
              }
              // MF autocalc section
              if (item.riskAssessmentLikelihoodLTCode && item.riskAssessmentConsequenceLTCode) {
                if (item.riskAssessmentLikelihoodLTCode == 'almost_certain') {
                    if (['major','severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_high') {
                            item.riskAssessmentRatingLTCode = 'very_high'
                            item.riskAssessmentRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['minor','medium'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                } 
                else if (item.riskAssessmentLikelihoodLTCode == 'rare') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"

                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible','minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'unlikely') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'likely') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_high') {
                            item.riskAssessmentRatingLTCode = 'very_high'
                            item.riskAssessmentRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    }
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'possible') {
                    if (['medium', 'major', 'severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    } 
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
              }
              else {
                  if (item.riskAssessmentRatingLTCode) {
                      item.riskAssessmentRatingLTCode = ''
                      item.riskAssessmentRatingLTLabel = ''
                  }
              }

              if (item.adaptationLikelihoodSTCode && item.adaptationConsequenceSTCode) {
                  if (item.adaptationLikelihoodSTCode == 'almost_certain') {
                      if (['major','severe'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'very_high') {
                              item.adaptationRiskRatingSTCode = 'very_high'
                              item.adaptationRiskRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['minor','medium'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'high') {
                              item.adaptationRiskRatingSTCode = 'high'
                              item.adaptationRiskRatingSTLabel = "High"
                          }
                      }
                      else if (['negligible'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'moderate') {
                              item.adaptationRiskRatingSTCode = 'moderate'
                              item.adaptationRiskRatingSTLabel = "Moderate"
                          }
                      }
                      else {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                  } 
                  else if (item.adaptationLikelihoodSTCode == 'rare') {
                      if (['severe'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'moderate') {
                              item.adaptationRiskRatingSTCode = 'moderate'
                              item.adaptationRiskRatingSTLabel = "Moderate"

                          }
                      } 
                      else if (['medium','major'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'low') {
                              item.adaptationRiskRatingSTCode = 'low'
                              item.adaptationRiskRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible','minor'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.adaptationLikelihoodSTCode == 'unlikely') {
                      if (['severe'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'high') {
                              item.adaptationRiskRatingSTCode = 'high'
                              item.adaptationRiskRatingSTLabel = "High"
                          }
                      } 
                      else if (['medium','major'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'moderate') {
                              item.adaptationRiskRatingSTCode = 'moderate'
                              item.adaptationRiskRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['minor'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'low') {
                              item.adaptationRiskRatingSTCode = 'low'
                              item.adaptationRiskRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.adaptationLikelihoodSTCode == 'likely') {
                      if (['severe'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'very_high') {
                              item.adaptationRiskRatingSTCode = 'very_high'
                              item.adaptationRiskRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['medium','major'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'high') {
                              item.adaptationRiskRatingSTCode = 'high'
                              item.adaptationRiskRatingSTLabel = "High"
                          }
                      }
                      else if (['minor'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'moderate') {
                              item.adaptationRiskRatingSTCode = 'moderate'
                              item.adaptationRiskRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['negligible'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'low') {
                              item.adaptationRiskRatingSTCode = 'low'
                              item.adaptationRiskRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.adaptationLikelihoodSTCode == 'possible') {
                      if (['medium', 'major', 'severe'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'high') {
                              item.adaptationRiskRatingSTCode = 'high'
                              item.adaptationRiskRatingSTLabel = "High"
                          }
                      } 
                      else if (['minor'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'moderate') {
                              item.adaptationRiskRatingSTCode = 'moderate'
                              item.adaptationRiskRatingSTLabel = "Moderate"
                          }
                      }
                      else if (['negligible'].includes(item.adaptationConsequenceSTCode)) {
                          if (item.adaptationRiskRatingSTCode != 'low') {
                              item.adaptationRiskRatingSTCode = 'low'
                              item.adaptationRiskRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.adaptationRiskRatingSTCode != 'very_low') {
                              item.adaptationRiskRatingSTCode = 'very_low'
                              item.adaptationRiskRatingSTLabel = "Very Low"
                          }
                      }
                  }
              }
              else {
                  if (item.adaptationRiskRatingSTCode) {
                      item.adaptationRiskRatingSTCode = ''
                      item.adaptationRiskRatingSTLabel = ''
                  }
              }
              // MF autocalc section
              if (item.adaptationLikelihoodLTCode && item.adaptationConsequenceLTCode) {
                if (item.adaptationLikelihoodLTCode == 'almost_certain') {
                    if (['major','severe'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'very_high') {
                            item.adaptationRiskRatingLTCode = 'very_high'
                            item.adaptationRiskRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['minor','medium'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'high') {
                            item.adaptationRiskRatingLTCode = 'high'
                            item.adaptationRiskRatingLTLabel = "High"
                        }
                    }
                    else if (['negligible'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'moderate') {
                            item.adaptationRiskRatingLTCode = 'moderate'
                            item.adaptationRiskRatingLTLabel = "Moderate"
                        }
                    }
                    else {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                } 
                else if (item.adaptationLikelihoodLTCode == 'rare') {
                    if (['severe'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'moderate') {
                            item.adaptationRiskRatingLTCode = 'moderate'
                            item.adaptationRiskRatingLTLabel = "Moderate"

                        }
                    } 
                    else if (['medium','major'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'low') {
                            item.adaptationRiskRatingLTCode = 'low'
                            item.adaptationRiskRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible','minor'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.adaptationLikelihoodLTCode == 'unlikely') {
                    if (['severe'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'high') {
                            item.adaptationRiskRatingLTCode = 'high'
                            item.adaptationRiskRatingLTLabel = "High"
                        }
                    } 
                    else if (['medium','major'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'moderate') {
                            item.adaptationRiskRatingLTCode = 'moderate'
                            item.adaptationRiskRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['minor'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'low') {
                            item.adaptationRiskRatingLTCode = 'low'
                            item.adaptationRiskRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.adaptationLikelihoodLTCode == 'likely') {
                    if (['severe'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'very_high') {
                            item.adaptationRiskRatingLTCode = 'very_high'
                            item.adaptationRiskRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['medium','major'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'high') {
                            item.adaptationRiskRatingLTCode = 'high'
                            item.adaptationRiskRatingLTLabel = "High"
                        }
                    }
                    else if (['minor'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'moderate') {
                            item.adaptationRiskRatingLTCode = 'moderate'
                            item.adaptationRiskRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['negligible'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'low') {
                            item.adaptationRiskRatingLTCode = 'low'
                            item.adaptationRiskRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.adaptationLikelihoodLTCode == 'possible') {
                    if (['medium', 'major', 'severe'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'high') {
                            item.adaptationRiskRatingLTCode = 'high'
                            item.adaptationRiskRatingLTLabel = "High"
                        }
                    } 
                    else if (['minor'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'moderate') {
                            item.adaptationRiskRatingLTCode = 'moderate'
                            item.adaptationRiskRatingLTLabel = "Moderate"
                        }
                    }
                    else if (['negligible'].includes(item.adaptationConsequenceLTCode)) {
                        if (item.adaptationRiskRatingLTCode != 'low') {
                            item.adaptationRiskRatingLTCode = 'low'
                            item.adaptationRiskRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.adaptationRiskRatingLTCode != 'very_low') {
                            item.adaptationRiskRatingLTCode = 'very_low'
                            item.adaptationRiskRatingLTLabel = "Very Low"
                        }
                    }
                }
              }
              else {
                  if (item.adaptationRiskRatingLTCode) {
                      item.adaptationRiskRatingLTCode = ''
                      item.adaptationRiskRatingLTLabel = ''
                  }
              }
              
        })
      },
      deep: true
    },
    'actionForm.measureCode': {
      handler (newValue, oldValue) {
        this.setActionFormDetails(newValue)
      }
    },
    'actionForm': {
      handler (newValue, oldValue) {
        let item = newValue
        item.mean = ((item.feasibility + item.indicativeCost + item.impact) / 3).toFixed(1)

        // let currentCRAID = item.craID
        // let currentSubAsset = item.assetLevel2Code
        // let currentHazard= item.climateHazardCode
        // console.log("new", item.measureCode);
        // console.log("old", oldValue.measureCode);
        // if(item.measureCode != oldValue.measureCode) {
        //   let currentItem = this.coreAdaptationActionsList.filter(obj=>obj.subAssetTypeCode == currentSubAsset && obj.climateHazardCode == currentHazard && obj.adaptationMeasureCode == item.measureCode)[0]
        //   if(item.measureCode && currentItem){
        //     item.measureDesc = currentItem.measure_description
        //     item.feasibility = currentItem.feasibility
        //     item.indicativeCost = currentItem.indicative_cost
        //     item.impact = currentItem.impact
        //     item.mean = currentItem.mean
        //     item.adaptationCategoryCode = currentItem.adaptationCategoryCode
        //     item.adaptationTypeCode = currentItem.adaptationTypeCode
        //   }

        // }

      },
      deep: true
    },
    initialCRAList: {
      handler(newValue) {
        this.craList = newValue;
      }
    },
    initialCampusLabel: {
      handler(newValue) {
        this.campusLabel = newValue;
      }
    },
    initialLoadstate: {
      handler(newValue) {
        this.loadState = newValue;
      }
    },
  },
  methods: {
    getCRAList: function (campusCodeData) {
      const current = this
      const url = baseApiUrl + 'campusClimateRiskEntries'
      const data = {
        params: {
          campusCode: campusCodeData
        },
      }
      axios.get(url,data).then(response=>{
        if (response.data) {
            current.craList = response.data.entries
            current.campusLabel = response.data.campus.label
        }
      })
      .catch(() => {
        ElNotification({
          type: 'error',
          message: 'Failed to get data',
          title: 'ERROR'
        })
      })
      .finally(() => {
        current.loadState = false
      })
    },
    setUpCRAList: function() {
      const current = this
      current.loadState = true
      let code = current.currentCampusCode
      if (current.type === "region") {
        code = current.currentRegionCode
      }
      current.$emit("fetchCRA", code)
    },
    selectedPage: function (pageNumber) {
      const current = this;
      current.currentPage = pageNumber;
    },
    editItem: function(item) {
      const current = this
      current.actionForm.craID = item.id
      current.craList.filter(obj=>obj.id == item.id)[0].isEdit = !current.craList.filter(obj=>obj.id == item.id)[0].isEdit
      if(!current.craList.filter(obj=>obj.id == item.id)[0].isEdit){
        current.setUpCRAList()
      }
    },
    saveItem: function(item) {
      const current = this
      current.craList.filter(obj=>obj.id == item.id)[0].isEdit = !current.craList.filter(obj=>obj.id == item.id)[0].isEdit
      current.loadState = true
    //   let data = {
    //     id: item.id,
    //     subAssetTypeCode: item.subAssetTypeCode,
    //     climateHazardCode: item.climateHazardCode,
    //     riskTypeCode: item.riskTypeCode,
    //     consequenceCategoryCode: item.consequenceCategoryCode,
    //     risk_statement: item.risk_statement,
    //     assumed_controls: item.assumed_controls
    //   }

    //   this.$store.dispatch('mainModule/updateCRAEntry', data).then(()=>{
        let adaptationSTData = {
          id: item.id,
          adaptationActionTypeCode: "short",
          likelihoodCode: item.adaptationLikelihoodSTCode,
          consequenceCode: item.adaptationConsequenceSTCode,
          riskRatingCode: item.adaptationRiskRatingSTCode
        }
        let adaptationLTData = {
          id: item.id,
          adaptationActionTypeCode: "long",
          likelihoodCode: item.adaptationLikelihoodLTCode,
          consequenceCode: item.adaptationConsequenceLTCode,
          riskRatingCode: item.adaptationRiskRatingLTCode
        }

        Promise.all([
            this.$store.dispatch('mainModule/updateRiskAdaptationCRAEntry', adaptationSTData),
            adaptationLTData.likelihoodCode && adaptationLTData.consequenceCode ? this.$store.dispatch('mainModule/updateRiskAdaptationCRAEntry', adaptationLTData):null
        ]).finally(() => {
            current.setUpCRAList()
            ElNotification({
                title: 'Risk Entry',
                message: 'Updated Successfully',
                type: 'success',
            })
            // current.loadState = false
        })

        // this.$store.dispatch('mainModule/updateRiskAdaptationCRAEntry', adaptationSTData).then(()=>{
        //   ElNotification({
        //     title: 'Risk Entry',
        //     message: 'Updated Successfully',
        //     type: 'success',
        //   })
        //   // current.getCRAList(current.currentCampusCode)
        //   current.setUpCRAList()
        // })
    //   })
    },
    deleteItem: function(item) {
      const current = this
      let data = {
        id: item.id
      }
      ElMessageBox.confirm(
        "Are you sure you want to delete this entry?",
        "",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "error",
          confirmButtonClass: "error",
        }
      )
      .then(() => {
        this.$store.dispatch('mainModule/deleteCRAEntry', data).then(()=>{
          // current.getCRAList(current.currentCampusCode)
          current.setUpCRAList()
          ElNotification({
            title: 'Risk Entry',
            message: 'Deleted Successfully',
            type: 'success',
          })
            
        })
      })
      .catch(() => {
        ElMessage({
          type: "info",
          message: "Deletion canceled",
        });
      });
    },
    addAdaptationAction: function(craID) {
      const current = this
      current.resetActionForm()  
      current.actionForm.craID = craID
      current.actionForm.climateHazardCode = current.craList.filter(obj=>obj.id == craID)[0].climateHazardCode
      current.actionForm.assetLevel2Code = current.craList.filter(obj=>obj.id == craID)[0].subAssetTypeCode
      // current.actionForm.adaptActionTypeCode = aaType
      current.dialogAddAdaptationAction = true
    },
    viewAdaptationAction: function(craID, aaAID, aaItem) {
      const current = this
      current.resetActionForm()  
      current.actionForm.craID = craID
      current.actionForm.craAdaptationActionDetailsId = aaItem.id
      current.actionForm.climateHazardCode = current.craList.filter(obj=>obj.id == craID)[0].climateHazardCode
      current.actionForm.assetLevel2Code = current.craList.filter(obj=>obj.id == craID)[0].subAssetTypeCode
      current.actionForm.measureCode = aaItem.adaptationMeasureCode
      current.actionForm.measureDesc = aaItem.measure_description
      current.actionForm.feasibility = aaItem.feasibility
      current.actionForm.indicativeCost = aaItem.indicative_cost
      current.actionForm.impact = aaItem.impact
      current.actionForm.mean = aaItem.mean
      current.actionForm.timeline = aaItem.timeline
      current.actionForm.adaptationControl = aaItem.adaptation_control
      current.actionForm.adaptationCategoryCode = aaItem.adaptationCategoryCode
      current.actionForm.adaptationTypeCode = aaItem.adaptationTypeCode
      current.actionForm.ownerResponsible = aaItem.owner_responsible
      current.actionForm.implementationTiming = aaItem.implementation_timing
      current.actionForm.isImplemented = aaItem.is_implemented == 1 ? true : false 
      current.dialogAddAdaptationAction = true
    },
    onSubmit: function() {
      const current = this
      let data = current.actionForm
      this.$store.dispatch('mainModule/saveAdaptationActionEntry', data).then(()=>{
        ElNotification({
          title: 'Risk Entry',
          message: 'Updated Successfully',
          type: 'success',
        })
        current.setUpCRAList()
        current.dialogAddAdaptationAction = false
      })
    },
    resetActionForm: function() {
      const current = this
      current.actionForm.craID = null
      current.actionForm.craAdaptationActionDetailsId =null
      current.actionForm.climateHazardCode = ""
      current.actionForm.assetLevel2Code = ""
      current.actionForm.measureCode = ""
      current.actionForm.measureDesc = ""
      current.actionForm.feasibility = 0
      current.actionForm.indicativeCost = 0
      current.actionForm.impact = 0
      current.actionForm.mean = 0
      current.actionForm.timeline = ""
      current.actionForm.adaptationControl = ""
      current.actionForm.adaptationCategoryCode = ""
      current.actionForm.adaptationTypeCode = ""
      current.actionForm.ownerResponsible = ""
      current.actionForm.implementationTiming = ""
      current.actionForm.isImplemented = 0 
    },
    setActionFormDetails: function(item){
      const current = this
      current.actionForm.mean = ((current.actionForm.feasibility + current.actionForm.indicativeCost + current.actionForm.impact) / 3).toFixed(1)

      // let currentCRAID = current.actionForm.craID
      let currentSubAsset = current.actionForm.assetLevel2Code
      let currentHazard= current.actionForm.climateHazardCode
      // let currentItem = this.coreAdaptationActionsList.filter(obj=>obj.subAssetTypeCode == currentSubAsset && obj.climateHazardCode == currentHazard && obj.adaptationMeasureCode == current.actionForm.measureCode)[0]
      // let currentItem = this.coreAdaptationActionsList.filter(obj=>obj.subAssetTypeCode == currentSubAsset  && obj.climateHazardCode == currentHazard && obj.adaptationMeasureCode == current.actionForm.measureCode)[0]
      // console.log("new", current.actionForm.measureCode);
      // console.log("old", oldValue.measureCode);
      // if(current.actionForm.measureCode != oldValue.measureCode) {
      let currentItem = []
      if (this.type === "region") {
        currentItem = this.coreAdaptationActionsList.filter(obj => 
          obj.climateHazardCode == currentHazard
          && obj.adaptationMeasureCode == item
        )[0]
      } else {
        currentItem = this.coreAdaptationActionsList.filter(obj => 
          obj.subAssetTypeCode == currentSubAsset
          && obj.climateHazardCode == currentHazard
          && obj.adaptationMeasureCode == item
        )[0]
      }

      if(current.actionForm.measureCode && currentItem){
        current.actionForm.measureDesc = currentItem.measure_description
        current.actionForm.feasibility = currentItem.feasibility
        current.actionForm.indicativeCost = currentItem.indicative_cost
        current.actionForm.impact = currentItem.impact
        current.actionForm.mean = currentItem.mean
        current.actionForm.adaptationCategoryCode = currentItem.adaptationCategoryCode
        current.actionForm.adaptationTypeCode = currentItem.adaptationTypeCode
        current.actionForm.timeline = currentItem.timeline
        current.actionForm.adaptationControl = currentItem.adaptation_control
      }
    },
    handleOnclickDownload: function() {
      let reqUrl = "";
      let fileName = "";
      if (this.type === "portfolio") {
        reqUrl = "downloadPortfolioAdaptationReports";
        fileName = "PortfolioAdaptationReport.xlsx"
      } else if (this.type === "region") {
        reqUrl = "downloadRegionAdaptationReports?regionCode=" + this.currentRegionCode;
        fileName = "RegionAdaptationReport.xlsx"

      } else {
        reqUrl = "downloadCampusAdaptationReports?campusCode=" + this.currentCampusCode;
        fileName = "CampusAdaptationReport.xlsx"
      }
      const url = baseApiUrl + reqUrl;
      axios.get(url, { responseType: 'blob' }).then(result => {
        const type = result.headers["content-type"];
        const link = document.createElement("a");
        link.style = "display: none"

        const blob = new Blob(
          [ result.data ],
          { type }
        )
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.download = fileName
        link.click()
        window.URL.revokeObjectURL(url)
      })
    }
  },
  mounted() {
    const current = this;
    current.windowHeight = window.innerHeight
    window.onresize = () => {
      current.windowHeight = window.innerHeight
    }
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}

</style>
<style>
.custom-tbl-header {
    color: #E4610F !important;
    /* background: #FFF !important; */
}
.el-pagination.is-background .btn-next.is-active, .el-pagination.is-background .btn-prev.is-active, .el-pagination.is-background .el-pager li.is-active {
    background-color: #E4610F !important;

}
.el-pager li:hover {
    color: #E4610F
}
</style>
  