<template>
  <el-table 
    :data="pageSelect"  
    header-cell-class-name="custom-tbl-header"
    size="small"
    :height="windowHeight*screenFactor"
    style="width: 100%"
    v-loading="loadState"
  >
    <!-- <el-table-column type="index" width="50" align="center"/> -->
    <el-table-column prop="climateHazardLabel" label="Climate Hazard" width="120" fixed="left" v-loading="loadState">
      <template #default="scope">
        <el-select v-model="scope.row.climateHazardCode" v-if="scope.row.isEdit" placeholder="Select" size="small">
          <el-option
            v-for="item in climateHazardsList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="subAssetTypeLabel" label="Asset Level 2" width="125" fixed="left">
      <template #default="scope">
        <el-select v-model="scope.row.subAssetTypeCode" v-if="scope.row.isEdit" placeholder="Select" size="small">
          <el-option
            v-for="item in subAssetTypesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="risk_statement" label="Risk Statement" width="230" fixed="left">
      <template #default="scope">
        <el-input 
          v-model="scope.row.risk_statement" 
          size="small" 
          v-if="scope.row.isEdit" 
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }">
        </el-input>
      </template>
    </el-table-column>
    <el-table-column prop="consequenceCategoryLabel" label="TAFE NSW Consequence Area" width="120" fixed="left">
      <template #default="scope">
        <el-select v-model="scope.row.consequenceCategoryCode" v-if="scope.row.isEdit" placeholder="Select" size="small">
          <el-option
            v-for="item in consequenceCategoriesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="assumed_controls" label="Assumed Controls" width="200" fixed="left">
      <template #default="scope">
        <el-input 
          v-model="scope.row.assumed_controls" 
          size="small" 
          v-if="scope.row.isEdit" 
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 6 }">
        </el-input>
      </template>
    </el-table-column>
    <el-table-column prop="riskTypeLabel" label="Risk Type" width="100" v-if="type !== 'region'">
      <template #default="scope">
        <el-select v-model="scope.row.riskTypeCode" v-if="scope.row.isEdit" placeholder="Select" size="small">
          <el-option
            v-for="item in riskTypesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="criticalCampusLabels" label="Critical Campuses" width="100" v-else>
      <template #default="scope">
        <el-select
          v-if="scope.row.isEdit"
          v-model="scope.row.criticalCampusCodes"
          placeholder="Select"
          size="small"
          multiple
        >
          <el-option
            v-for="item in campusList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column label="Risk assessment" align="center">
      <el-table-column label="Short term" align="center">
        <el-table-column prop="riskAssessmentLikelihoodSTLabel" label="Likelihood" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.riskAssessmentLikelihoodSTCode" 
              v-if="scope.row.isEdit" 
              placeholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="riskAssessmentConsequenceSTLabel" label="Consequence" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.riskAssessmentConsequenceSTCode" 
              v-if="scope.row.isEdit" 
              placeholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="riskAssessmentRatingSTLabel" label="Risk Rating" width="120">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" effect="dark" v-if="scope.row.riskAssessmentRatingSTCode == 'very_high'">{{ scope.row.riskAssessmentRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="danger" v-if="scope.row.riskAssessmentRatingSTCode == 'high'">{{ scope.row.riskAssessmentRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="warning" effect="dark" v-if="scope.row.riskAssessmentRatingSTCode == 'moderate'">{{ scope.row.riskAssessmentRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" v-if="scope.row.riskAssessmentRatingSTCode == 'low'">{{ scope.row.riskAssessmentRatingSTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" effect="dark" v-if="scope.row.riskAssessmentRatingSTCode == 'very_low'">{{ scope.row.riskAssessmentRatingSTLabel }}</el-tag>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Long term" align="center">
        <el-table-column prop="riskAssessmentLikelihoodLTLabel" label="Likelihood" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.riskAssessmentLikelihoodLTCode" 
              v-if="scope.row.isEdit" 
              placeholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="riskAssessmentConsequenceLTLabel" label="Consequence" width="120">
          <template #default="scope">
            <el-select 
              v-model="scope.row.riskAssessmentConsequenceLTCode" 
              v-if="scope.row.isEdit" 
              laceholder="Select" 
              size="small">
              <el-option
                v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="riskAssessmentRatingLTLabel" label="Risk Rating" width="120">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" effect="dark" v-if="scope.row.riskAssessmentRatingLTCode == 'very_high'">{{ scope.row.riskAssessmentRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="danger" v-if="scope.row.riskAssessmentRatingLTCode == 'high'">{{ scope.row.riskAssessmentRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="warning" effect="dark" v-if="scope.row.riskAssessmentRatingLTCode == 'moderate'">{{ scope.row.riskAssessmentRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" v-if="scope.row.riskAssessmentRatingLTCode == 'low'">{{ scope.row.riskAssessmentRatingLTLabel }}</el-tag>
            <el-tag class="ml-2" type="success" effect="dark" v-if="scope.row.riskAssessmentRatingLTCode == 'very_low'">{{ scope.row.riskAssessmentRatingLTLabel }}</el-tag>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table-column>
    <el-table-column width="115" fixed="right" align="center">
      <template #header>
        Actions
      </template>
      <template #default="scope">
        <el-tooltip content="Edit Details" placement="bottom" effect="light">
          <!-- <el-button type="primary" link @click="editItem(scope.row)" >
            <i class="fa fa-edit large"></i>
          </el-button> -->
          <el-button type="primary" size="small" @click="editItem(scope.row)"  circle><i class="fa fa-edit large"></i></el-button>
        </el-tooltip>
        <el-tooltip content="Save Details" placement="bottom" v-if="scope.row.isEdit" effect="light">
          <!-- <el-button type="success" link @click="saveItem(scope.row)">
            <i class="fa fa-save large"></i>
          </el-button> -->
          <el-button type="success" size="small" @click="saveItem(scope.row)" circle><i class="fa fa-save large"></i></el-button>
        </el-tooltip>
        <el-tooltip content="Delete" placement="bottom" effect="light">
          <!-- <el-button type="danger" link @click="deleteItem(scope.row)">
            <i class="fa fa-trash large"></i>
          </el-button> -->
          <el-button type="danger" size="small" @click="deleteItem(scope.row)" circle><i class="fa fa-trash large"></i></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
  <el-row>
    <el-col :span="12" >
      
      <el-pagination
        background
        small
        :page-size="parseInt(perPage ? perPage : 1)"
        layout="total, prev, pager, next"
        :total="craList.length"
        :default-current-page="1"
        @current-change="selectedPage"
        style="margin-top:1rem"
      />
    </el-col>
    <el-col :span="12" align="right">
      <el-tooltip content="Add New Risk Entry" placement="bottom" effect="light">
        <el-button color="#E4610F" class="mt-2" size="large" @click="dialogAddCampusCRAEntry = true" circle><i class="fa fa-plus"></i></el-button>
      </el-tooltip>
      <el-tooltip content="Download" placement="bottom" effect="light">
        <el-button @click="handleOnclickDownload" type="primary" class="mt-2" size="large" circle><i class="fa-solid fa-download"></i></el-button>
      </el-tooltip>
    </el-col>
    
  </el-row>
  
  <el-dialog v-model="dialogAddCampusCRAEntry" title="Shipping address" draggable >
    <template #header>
      <h4>Create new risk entry</h4>
    </template>
    <el-form :model="craForm" label-width="auto" label-position="left" size="small">
      
      <el-form-item label="Campus" v-if="type === 'campus'">
        <el-select v-model="craForm.campusCode" placeholder="Select">
          <el-option
            v-for="item in campusList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Climate Hazard">
        <el-select v-model="craForm.climateHazardCode" placeholder="Select" size="small">
          <el-option
            v-for="item in climateHazardsList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Asset Type 2">
        <el-select v-model="craForm.subAssetTypeCode" placeholder="Select">
          <el-option
            v-for="item in subAssetTypesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Risk Statement">
        <el-input v-model="craForm.riskStatement" type="textarea" />
      </el-form-item>
      <el-form-item label="TAFE NSW Consequence Area">
        <el-select v-model="craForm.consequenceCategoryCode" placeholder="Select">
          <el-option
            v-for="item in consequenceCategoriesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Assumed Controls">
        <el-input v-model="craForm.assumedControls" type="textarea" />
      </el-form-item>
      <el-form-item label="Risk Type" class="pb-3" v-if="type !== 'region'">
        <el-select v-model="craForm.riskTypeCode" placeholder="Select" >
          <el-option
            v-for="item in riskTypesList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Critical Campuses" class="pb-3" v-else>
        <el-select v-model="craForm.criticalCampusCodes" placeholder="Select" multiple>
          <el-option
            v-for="item in campusList"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
      <el-divider />
      <el-text class="mx-1" size="large">Risk Assessment</el-text>
      <!-- <el-divider content-position="left"><el-text class="mx-1" size="large">Risk Assessment</el-text></el-divider> -->
      <!-- <el-divider content-position="left"><el-text class="mx-1" size="large">Short Term</el-text></el-divider> -->
      <!-- <br>
      <br> -->
      <!-- <el-text class="mx-1" size="large">Short Term</el-text> -->
      <el-form-item label="Short Term - Likelihood" class="pt-3">
        <!-- <el-select 
              v-model="craForm.riskAssessmentLikelihoodSTCode" 
              placeholder="Select">
              <el-option
                v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select> -->
            <el-radio-group v-model="craForm.riskAssessmentLikelihoodSTLabel" fill="#E4610F">
              <el-radio-button v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"/>
            </el-radio-group>
      </el-form-item>
      <el-form-item label="Short Term - Consequence">
        <!-- <el-select 
              v-model="craForm.riskAssessmentConsequenceSTCode"  
              placeholder="Select">
              <el-option
                v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select> -->
            <el-radio-group v-model="craForm.riskAssessmentConsequenceSTLabel" fill="#E4610F">
              <el-radio-button v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label" />
            </el-radio-group>
      </el-form-item>
      <el-form-item label="Short Term - Risk Rating" >
        <el-tag class="ml-2" type="danger" effect="dark" v-if="craForm.riskAssessmentRatingSTCode == 'very_high'">{{ craForm.riskAssessmentRatingSTLabel }}</el-tag>
        <el-tag class="ml-2" type="danger" v-if="craForm.riskAssessmentRatingSTCode == 'high'">{{ craForm.riskAssessmentRatingSTLabel }}</el-tag>
        <el-tag class="ml-2" type="warning" effect="dark" v-if="craForm.riskAssessmentRatingSTCode == 'moderate'">{{ craForm.riskAssessmentRatingSTLabel }}</el-tag>
        <el-tag class="ml-2" type="success" v-if="craForm.riskAssessmentRatingSTCode == 'low'">{{ craForm.riskAssessmentRatingSTLabel }}</el-tag>
        <el-tag class="ml-2" type="success" effect="dark" v-if="craForm.riskAssessmentRatingSTCode == 'very_low'">{{ craForm.riskAssessmentRatingSTLabel }}</el-tag>
      </el-form-item>

      <!-- <el-divider content-position="left"><el-text class="mx-1" size="large">Long Term</el-text></el-divider>
       -->
       <!-- <el-text class="mx-1" size="large">Long Term</el-text> -->
      <el-form-item label="Long Term - Likelihood" class="pt-2">
        <!-- <el-select 
              v-model="craForm.riskAssessmentLikelihoodLTCode" 
              placeholder="Select">
              <el-option
                v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select> -->
            <el-radio-group v-model="craForm.riskAssessmentLikelihoodLTLabel" fill="#E4610F">
              <el-radio-button v-for="item in assessmentLikelihoodList"
                :key="item.code"
                :label="item.label"/>
            </el-radio-group>
      </el-form-item>
      <el-form-item label="Long Term - Consequence">
        <!-- <el-select 
              v-model="craForm.riskAssessmentConsequenceLTCode"  
              placeholder="Select">
              <el-option
                v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select> -->
            <el-radio-group v-model="craForm.riskAssessmentConsequenceLTLabel" fill="#E4610F">
              <el-radio-button v-for="item in assessmentConsequencesList"
                :key="item.code"
                :label="item.label"/>
            </el-radio-group>
      </el-form-item>
      <el-form-item label="Long Term - Risk Rating" >
        <el-tag class="ml-2" type="danger" effect="dark" v-if="craForm.riskAssessmentRatingLTCode == 'very_high'">{{ craForm.riskAssessmentRatingLTLabel }}</el-tag>
        <el-tag class="ml-2" type="danger" v-if="craForm.riskAssessmentRatingLTCode == 'high'">{{ craForm.riskAssessmentRatingLTLabel }}</el-tag>
        <el-tag class="ml-2" type="warning" effect="dark" v-if="craForm.riskAssessmentRatingLTCode == 'moderate'">{{ craForm.riskAssessmentRatingLTLabel }}</el-tag>
        <el-tag class="ml-2" type="success" v-if="craForm.riskAssessmentRatingLTCode == 'low'">{{ craForm.riskAssessmentRatingLTLabel }}</el-tag>
        <el-tag class="ml-2" type="success" effect="dark" v-if="craForm.riskAssessmentRatingLTCode == 'very_low'">{{ craForm.riskAssessmentRatingLTLabel }}</el-tag>
      </el-form-item>
      
      
      
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogAddCampusCRAEntry = false" color="#E4610F" plain>Cancel</el-button>
        <el-button @click="onSubmit" color="#E4610F">
          Save
        </el-button>
      </span>
    </template>
  </el-dialog>
  
</template>

<script>
/*eslint-disable */
import { ElMessage, ElMessageBox } from "element-plus";
import { ElNotification } from 'element-plus'
import {
  Check,
  Delete,
  Edit
} from '@element-plus/icons-vue'
import axios from "axios";
import { baseApiUrl } from "../../config.js";

export default {
  name: 'RiskTable',
  components: {
    Check,
    Delete,
    Edit
  },
  emits: ["fetchCRA"],
  props: ["initialCRAList", "initialLoadstate", "type"],
  data() {
    return {
      craForm: {
        campusCode: this.currentCampusCode,
        climateHazardCode: '',
        subAssetTypeCode: '',
        riskStatement: '',
        consequenceCategoryCode: '',
        assumedControls: '',
        riskTypeCode: '',
        riskAssessmentLikelihoodSTCode: '',
        riskAssessmentLikelihoodSTLabel: '',
        riskAssessmentConsequenceSTCode: '',
        riskAssessmentConsequenceSTLabel: '',
        riskAssessmentRatingSTCode: '',
        riskAssessmentRatingSTLabel: '',
        riskAssessmentLikelihoodLTCode: '',
        riskAssessmentLikelihoodLTLabel: '',
        riskAssessmentConsequenceLTCode: '',
        riskAssessmentConsequenceLTLabel: '',
        riskAssessmentRatingLTCode: '',
        criticalCampusCodes: [],
      },
      windowHeight: 0,
      screenFactor: 0.55,
      dialogAddCampusCRAEntry: false,
      loadState: false,
      selectedCampus: null,
      craList: [],
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    campusList: {
      get() {
        const stateCampusList = this.$store.state.mainModule.campusList
        return stateCampusList 
          ? stateCampusList.sort((a, b) => {
            if (a.code > b.code) {
              return 1
            } else if (a.code < b.code) {
              return -1
            } else {
              return 0
            }
          })
          : []
      }
    },
    currentCampusCode: {
        get() {
            return this.$store.state.mainModule.currentCampus?this.$store.state.mainModule.currentCampus:"albury"
        }
    },
    currentAssessment: {
        get() {
            return this.$store.state.mainModule.currentAssessment
        }
    },
    climateHazardsList: {
        get() {
            return this.$store.state.mainModule.climateHazardsList
        }
    },
    subAssetTypesList: {
        get() {
            return this.$store.state.mainModule.subAssetTypesList
        }
    },
    consequenceCategoriesList: {
        get() {
            return this.$store.state.mainModule.consequenceCategoriesList
        }
    },
    riskTypesList: {
        get() {
            return this.$store.state.mainModule.riskTypesList
        }
    },
    assessmentLikelihoodList: {
        get() {
            return this.$store.state.mainModule.assessmentLikelihoodList
        }
    },
    assessmentConsequencesList: {
        get() {
            return this.$store.state.mainModule.assessmentConsequencesList
        }
    },
    pageSelect: function() {
      let pageList = [];
      let newFilteredList = [].concat(this.craList);
      let itemsPerPage = this.perPage ? this.perPage : 1
      for (let i = 0; i < this.craList.length / itemsPerPage; i++) {
        pageList.push(newFilteredList.splice(0, itemsPerPage));
      }
      return pageList[this.currentPage - 1];
    },
    currentRegionCode: {
      get() {
        return this.$store.state.mainModule.currentRegion || "north"
      }
    },
  },
  watch: {
    'craList': {
      handler (newValue, oldValue) {
        newValue.forEach((item) => {
              if (item.riskAssessmentLikelihoodSTCode && item.riskAssessmentConsequenceSTCode) {
                  if (item.riskAssessmentLikelihoodSTCode == 'almost_certain') {
                      if (['major','severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_high') {
                              item.riskAssessmentRatingSTCode = 'very_high'
                              item.riskAssessmentRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['minor','medium'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  } 
                  else if (item.riskAssessmentLikelihoodSTCode == 'rare') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"

                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible','minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'unlikely') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'likely') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_high') {
                              item.riskAssessmentRatingSTCode = 'very_high'
                              item.riskAssessmentRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      }
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'possible') {
                      if (['medium', 'major', 'severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      } 
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
              }
              else {
                  if (item.riskAssessmentRatingSTCode) {
                      item.riskAssessmentRatingSTCode = ''
                      item.riskAssessmentRatingSTLabel = ''
                  }
              }
              // MF autocalc section
              if (item.riskAssessmentLikelihoodLTCode && item.riskAssessmentConsequenceLTCode) {
                if (item.riskAssessmentLikelihoodLTCode == 'almost_certain') {
                    if (['major','severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_high') {
                            item.riskAssessmentRatingLTCode = 'very_high'
                            item.riskAssessmentRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['minor','medium'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                } 
                else if (item.riskAssessmentLikelihoodLTCode == 'rare') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"

                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible','minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'unlikely') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'likely') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_high') {
                            item.riskAssessmentRatingLTCode = 'very_high'
                            item.riskAssessmentRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    }
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'possible') {
                    if (['medium', 'major', 'severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    } 
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
              }
              else {
                  if (item.riskAssessmentRatingLTCode) {
                      item.riskAssessmentRatingLTCode = ''
                      item.riskAssessmentRatingLTLabel = ''
                  }
              }
              
        })
      },
      deep: true
    },
    'craForm': {
      handler (newValue, oldValue) {
        let item = newValue
        item.riskAssessmentLikelihoodSTCode = (item.riskAssessmentLikelihoodSTLabel.replace(" ", "_")).toLowerCase()
        item.riskAssessmentConsequenceSTCode = (item.riskAssessmentConsequenceSTLabel.replace(" ", "_")).toLowerCase()
        item.riskAssessmentLikelihoodLTCode = (item.riskAssessmentLikelihoodLTLabel.replace(" ", "_")).toLowerCase()
        item.riskAssessmentConsequenceLTCode = (item.riskAssessmentConsequenceLTLabel.replace(" ", "_")).toLowerCase()
        // newValue.forEach((item) => {
              if (item.riskAssessmentLikelihoodSTCode && item.riskAssessmentConsequenceSTCode) {
                  if (item.riskAssessmentLikelihoodSTCode  == 'almost_certain') {
                    if (['major','severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_high') {
                              item.riskAssessmentRatingSTCode = 'very_high'
                              item.riskAssessmentRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['minor','medium'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  } 
                  else if (item.riskAssessmentLikelihoodSTCode == 'rare') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"

                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible','minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'unlikely') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'likely') {
                      if (['severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'very_high') {
                              item.riskAssessmentRatingSTCode = 'very_high'
                              item.riskAssessmentRatingSTLabel = "Very High"
                          }
                      } 
                      else if (['medium','major'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      }
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = 'Moderate'
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
                  else if (item.riskAssessmentLikelihoodSTCode == 'possible') {
                      if (['medium', 'major', 'severe'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'high') {
                              item.riskAssessmentRatingSTCode = 'high'
                              item.riskAssessmentRatingSTLabel = "High"
                          }
                      } 
                      else if (['minor'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'moderate') {
                              item.riskAssessmentRatingSTCode = 'moderate'
                              item.riskAssessmentRatingSTLabel = "Moderate"
                          }
                      }
                      else if (['negligible'].includes(item.riskAssessmentConsequenceSTCode)) {
                          if (item.riskAssessmentRatingSTCode != 'low') {
                              item.riskAssessmentRatingSTCode = 'low'
                              item.riskAssessmentRatingSTLabel = 'Low'
                          }
                      }
                      else {
                          if (item.riskAssessmentRatingSTCode != 'very_low') {
                              item.riskAssessmentRatingSTCode = 'very_low'
                              item.riskAssessmentRatingSTLabel = "Very Low"
                          }
                      }
                  }
              }
              else {
                  if (item.riskAssessmentRatingSTCode) {
                      item.riskAssessmentRatingSTCode = ''
                      item.riskAssessmentRatingSTLabel = ''
                  }
              }
              // MF autocalc section
              if (item.riskAssessmentLikelihoodLTCode && item.riskAssessmentConsequenceLTCode) {
                if (item.riskAssessmentLikelihoodLTCode == 'almost_certain') {
                    if (['major','severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_high') {
                            item.riskAssessmentRatingLTCode = 'very_high'
                            item.riskAssessmentRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['minor','medium'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                } 
                else if (item.riskAssessmentLikelihoodLTCode == 'rare') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"

                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible','minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'unlikely') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'likely') {
                    if (['severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'very_high') {
                            item.riskAssessmentRatingLTCode = 'very_high'
                            item.riskAssessmentRatingLTLabel = "Very High"
                        }
                    } 
                    else if (['medium','major'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    }
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = 'Moderate'
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
                else if (item.riskAssessmentLikelihoodLTCode == 'possible') {
                    if (['medium', 'major', 'severe'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'high') {
                            item.riskAssessmentRatingLTCode = 'high'
                            item.riskAssessmentRatingLTLabel = "High"
                        }
                    } 
                    else if (['minor'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'moderate') {
                            item.riskAssessmentRatingLTCode = 'moderate'
                            item.riskAssessmentRatingLTLabel = "Moderate"
                        }
                    }
                    else if (['negligible'].includes(item.riskAssessmentConsequenceLTCode)) {
                        if (item.riskAssessmentRatingLTCode != 'low') {
                            item.riskAssessmentRatingLTCode = 'low'
                            item.riskAssessmentRatingLTLabel = 'Low'
                        }
                    }
                    else {
                        if (item.riskAssessmentRatingLTCode != 'very_low') {
                            item.riskAssessmentRatingLTCode = 'very_low'
                            item.riskAssessmentRatingLTLabel = "Very Low"
                        }
                    }
                }
              }
              else {
                  if (item.riskAssessmentRatingLTCode) {
                      item.riskAssessmentRatingLTCode = ''
                      item.riskAssessmentRatingLTLabel = ''
                  }
              }
              
        // })
      },
      deep: true
    },
    initialCRAList: {
      handler(newValue) {
        this.craList = newValue;
      }
    },
    initialLoadstate: {
      handler(newValue) {
        this.loadState = newValue;
      }
    },
  },
  methods: {
    setUpCRAList: function() {
      const current = this
      current.loadState = true
      let code = current.currentCampusCode
      if (current.type === "region") {
        code = current.currentRegionCode
      }
      current.$emit("fetchCRA", code)
    },
    selectedPage: function (pageNumber) {
      const current = this;
      current.currentPage = pageNumber;
    },
    editItem: function(item) {
      const current = this
      current.craList.filter(obj=>obj.id == item.id)[0].isEdit = !current.craList.filter(obj=>obj.id == item.id)[0].isEdit
      if(!current.craList.filter(obj=>obj.id == item.id)[0].isEdit){
        current.setUpCRAList()
      }
    },
    saveItem: function(item) {
      const current = this
      current.craList.filter(obj=>obj.id == item.id)[0].isEdit = !current.craList.filter(obj=>obj.id == item.id)[0].isEdit
      current.loadState = true
      let data = {
        id: item.id,
        subAssetTypeCode: item.subAssetTypeCode,
        climateHazardCode: item.climateHazardCode,
        riskTypeCode: item.riskTypeCode,
        consequenceCategoryCode: item.consequenceCategoryCode,
        risk_statement: item.risk_statement,
        assumed_controls: item.assumed_controls,
        critical_campuses: item.criticalCampusCodes,
      }

      this.$store.dispatch('mainModule/updateCRAEntry', data).then(()=>{
        let assessmentData = {
          id: item.id,
          riskAssessmentLikelihoodSTCode: item.riskAssessmentLikelihoodSTCode,
          riskAssessmentConsequenceSTCode: item.riskAssessmentConsequenceSTCode,
          riskAssessmentRatingSTCode: item.riskAssessmentRatingSTCode,
          riskAssessmentLikelihoodLTCode: item.riskAssessmentLikelihoodLTCode,
          riskAssessmentConsequenceLTCode: item.riskAssessmentConsequenceLTCode,
          riskAssessmentRatingLTCode: item.riskAssessmentRatingLTCode,
          treatmentRiskAssessmentLikelihoodSTCode: item.treatmentRiskAssessmentLikelihoodSTCode,
          treatmentRiskAssessmentConsequenceSTCode: item.treatmentRiskAssessmentConsequenceSTCode,
          treatmentRiskRatingSTCode: item.treatmentRiskRatingSTCode,
          treatmentRiskAssessmentLikelihoodLTCode: item.treatmentRiskAssessmentLikelihoodLTCode,
          treatmentRiskAssessmentConsequenceLTCode: item.treatmentRiskAssessmentConsequenceLTCode,
          treatmentRiskRatingLTCode: item.treatmentRiskRatingLTCode
        }
        this.$store.dispatch('mainModule/updateRiskAssessmentCRAEntry', assessmentData).then(()=>{
          ElNotification({
            title: 'Risk Entry',
            message: 'Updated Successfully',
            type: 'success',
          })
          current.setUpCRAList()
        })
      })
    },
    deleteItem: function(item) {
      const current = this
      let data = {
        id: item.id
      }
      ElMessageBox.confirm(
        "Are you sure you want to delete this entry?",
        "",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "error",
          confirmButtonClass: "error",
        }
      )
        .then(() => {
          this.$store.dispatch('mainModule/deleteCRAEntry', data).then(()=>{
            current.setUpCRAList()
            ElNotification({
              title: 'Risk Entry',
              message: 'Deleted Successfully',
              type: 'success',
            })
              
          })
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Deletion canceled",
          });
        });
    },
    onSubmit: function() {
      const current = this
      const data = {
        ...current.craForm,
        levelCode: current.type,
        regionCode: this.currentRegionCode,
      }
      this.$store.dispatch('mainModule/saveCRAEntry', data).then(()=>{
        
          ElNotification({
            title: 'Risk Entry',
            message: 'Updated Successfully',
            type: 'success',
          })
          // current.getCRAList(current.currentCampusCode)
          current.setUpCRAList()
          current.dialogAddCampusCRAEntry = false
      })
    },
    handleOnclickDownload: function() {
      let reqUrl = "";
      let filename = "";
      if (this.type === "portfolio") {
        reqUrl = "downloadPortfolioReports";
        filename = "PortfolioReport.xlsx";
      } else if (this.type === "campus") {
        reqUrl = "downloadCampusReports?campusCode=" + this.currentCampusCode;
        filename = "CampusReport.xlsx";
      } else {
        reqUrl = "downloadRegionReports?regionCode=" + this.currentRegionCode;
        filename = "RegionReport.xlsx";
      }
      const url = baseApiUrl + reqUrl;
      axios.get(url, { responseType: 'blob' }).then(result => {
        const type = result.headers["content-type"];
        const link = document.createElement("a");
        link.style = "display: none"

        const blob = new Blob(
          [ result.data ],
          { type }
        )
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.download = filename
        link.click()
        window.URL.revokeObjectURL(url)
      })
    }
  },
  mounted() {
    const current = this;
    current.windowHeight = window.innerHeight
    window.onresize = () =>{
      current.windowHeight = window.innerHeight
    }
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}

.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}

</style>
<style>
.custom-tbl-header {
    color: #E4610F !important;
    /* background: #FFF !important; */
}
.el-pagination.is-background .btn-next.is-active, .el-pagination.is-background .btn-prev.is-active, .el-pagination.is-background .el-pager li.is-active {
    background-color: #E4610F !important;

}
.el-pager li:hover {
    color: #E4610F
}
</style>
  