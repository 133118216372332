<template>
  <div style="width: 100%;">
    <el-row :span="24">
      <el-scrollbar max-height="95dvh">
        <el-main >
          <el-row :gutter="12" class="mb-2">
            <el-col >
              <el-card shadow="always"> 
                <el-text tag="b" class="arc-primary-font" size="large">
                  RAAP Components
                </el-text>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="12">
            <el-col >
              <el-card shadow="always" style="width: 98dvw">
                <el-text tag="p" class="arc-primary-font" size="large">
                  The Risk and Adaptation Platform provides three key components at each assessment scale (Portfolio, Region and Campus):

                </el-text>
                <div style="text-align: center;">
                  <el-image src="HomePageDiagram.png" style="height: 270px"/>
                </div>
                <!-- <el-row>
                  <el-col :span="5"></el-col>
                  <el-col :span="14"><img src="HomePageDiagram.png" height="300"/></el-col>
                  <el-col :span="5"></el-col>
                </el-row> -->
                
                <!-- <el-collapse v-model="activeAboutAccordion" accordion>
                  <el-collapse-item name="1">
                    <template #title>
                      <el-text tag="b" size="large" class="arc-orange-font"> Project Context</el-text>
                    </template>
                    <div>
                      <el-text tag="p" size="small" class="arc-primary-font">
                        Technical and Further Education New South Wales (TAFE NSW) recognises that the future climate presents significant risk to its strategic objectives and goals. Specifically, there is a need to integrate climate change risk assessment and adaptation planning into its 20 Year Infrastructure Strategy (20YIS).
                        This Strategy has a vision to create
                      </el-text>
                      <br>
                      <b-list-group>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">A great place for the community</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">Flexible and adaptable learning spaces</el-text>
                        </b-list-group-item>
                        <b-list-group-item>
                          <el-text tag="p" class="arc-primary-font" size="small">A safe, sustainable and resilient portfolio</el-text>
                        </b-list-group-item>
                      </b-list-group>
                      <br>
                      <el-text tag="p" class="arc-primary-font" size="small">
                        Realising this vision will require an adaptive, comprehensive and integrated climate change risk management approach. comprehensive and integrated climate change risk management approach. The TAFE State-wide Climate Risk Assessments seeks to build on the work TAFE NSW has completed to date in building portfolio-wide resilience to climate change.
                      </el-text>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item name="2">
                    <template #title>
                      <el-text tag="b" size="large" class="arc-orange-font"> Project Objectives</el-text>
                    </template>
                    <div>
                      <el-text tag="p" class="arc-primary-font" size="small">
                      The project will have the following objectives:
                    </el-text>
                    <br>
                    <b-list-group>
                      <b-list-group-item>
                        <el-text tag="p" class="arc-primary-font" size="small">Identification of climate risk - Understand how climate risks may impact on physical infrastructure</el-text>
                      </b-list-group-item>
                      <b-list-group-item>
                        <el-text tag="p" class="arc-primary-font" size="small">Prioritise responses to climate change risk - Highlight areas that warrant further investigation or investment</el-text>
                      </b-list-group-item>
                      <b-list-group-item>
                        <el-text tag="p" class="arc-primary-font" size="small">Seize opportunities - Increase understanding, and capacity to respond to climate related opportunities</el-text>
                      </b-list-group-item>
                      <b-list-group-item>
                        <el-text tag="p" class="arc-primary-font" size="small">Improves enterprise and campus level oversight and management of climate risks - embed climate risk management in existing frameworks and procedures</el-text>
                      </b-list-group-item>
                      <b-list-group-item>
                        <el-text tag="p" class="arc-primary-font" size="small">Adapt and build climate resilience - Inform decisions about risk treatments to be implemented over time</el-text>
                      </b-list-group-item>
                      <b-list-group-item>
                        <el-text tag="p" class="arc-primary-font" size="small">A key aspect of TAFE NSW 20YIS is to create a safe, sustainable and resilient portfolio. The outcomes of this project will deliver management actions that will support the transformation toward a low carbon and environmentally sustainable portfolio. Adaptation actions should be holistic and have multiple benefits such as improved electricity efficiency and optimised whole of life costs. Adaptation actions will be integrated with the asset renewal program to optimise costs, risks and performance of the portfolio</el-text>
                      </b-list-group-item>
                    </b-list-group>
                    </div>
                  </el-collapse-item>
                </el-collapse>    -->
              </el-card>
            </el-col>
          </el-row>
  
          
  
          <el-row :gutter="12" class="mt-3">
            <el-col :span="8">
              <el-card shadow="always"> 
                <el-row :gutter="20">
                  <el-col :span="4">
                    <el-avatar :size="70" class="arc-light-orange-bg"> <el-icon :size="30"><Suitcase /></el-icon> </el-avatar>
                  </el-col>
                  <el-col :span="16">
                    <br>
                    <el-text tag="b" size="large" class="arc-orange-font"> <h4>PORTFOLIO</h4></el-text>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="4">
                  </el-col>
                  <el-col :span="16">
                    <!-- <img
                      src="portfolio_menu.jpg"
                      class="image"
                    /> -->
                    <el-image style="width: 100%; height: 20vh" src="portfolio_menu.jpg" fit="fill" />
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="16">
                  </el-col>
                  <el-col :span="4" class="mt-3">
                    <el-button type="warning" size="large"  @click="reroute('portfolio')" round>VIEW</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">  
                <el-row :gutter="20">
                  <el-col :span="4">
                    <el-avatar :size="70" class="arc-light-orange-bg"> <el-icon :size="30"><LocationInformation /></el-icon> </el-avatar>
                  </el-col>
                  <el-col :span="16">
                    <br>
                    <el-text tag="b" size="large" class="arc-orange-font"> <h4>REGION</h4></el-text>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="4">
                  </el-col>
                  <el-col :span="16">
                    <!-- <img
                      src="region_menu.jpg"
                      class="image"
                    /> -->
                    <el-image style="width: 100%; height: 20vh" src="region_menu.jpg" fit="fill" />
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="16">
                  </el-col>
                  <el-col :span="4" class="mt-3">
                    <el-button type="warning" size="large" @click="reroute('region')" round>VIEW</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="always">  
                <el-row :gutter="20">
                  <el-col :span="4">
                    <el-avatar :size="70" class="arc-light-orange-bg"> <el-icon :size="30"><School /></el-icon> </el-avatar>
                  </el-col>
                  <el-col :span="16">
                    <br>
                    <el-text tag="b" size="large" class="arc-orange-font"> <h4>CAMPUS</h4></el-text>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="4">
                  </el-col>
                  <el-col :span="16">
                    <!-- <img
                      src="campus_menu.jpg"
                      class="image"
                    /> -->
                    <el-image style="width: 100%; height: 20vh" src="campus_menu.jpg" fit="fill" />
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="16">
                  </el-col>
                  <el-col :span="4" class="mt-3">
                    <el-button type="warning" size="large" @click="reroute('campus')" round>VIEW</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-main>
      </el-scrollbar>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable */
import {
  Suitcase,
  LocationInformation,
  School
} from '@element-plus/icons-vue'
import { ref } from 'vue'
export default {
  name: 'Home',
  components: {
    Suitcase,
    LocationInformation,
    School
  },
  data(){
    return {
      activeAboutAccordion: ref('1')
    }
  },
  methods: {
    reroute: function(item) {
      this.$store.commit('mainModule/setCurrentAssessment', item)
      this.$router.push({ path: '/' + item })
    }
  }
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.image {
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
  height: 20vh;
  width: 20vw;
  display: block;
  border-radius: 1%;
}
</style>
  